import { chauffeurConstants, socketConstants, tripConstants, vehicleConstants } from '@constants';

const initialState = {
  isFetchingChauffeur: false,
  formChauffeur: null,
  chauffeur: null,

  isFetchingTrips: false,

  isFetchingChauffeurs: false,
  isFetchingChauffeursFrom: 0,
  chauffeursTotalResults: 0,
  chauffeursLoaded: 0,
  chauffeurs: [],

  isFetchingExtendedChauffeurs: false,
  isFetchingExtendedChauffeursFrom: 0,
  extendedChauffeursTotalResults: 0,
  extendedChauffeursLoaded: 0,
  extendedChauffeurs: [],
  isFetchingActivities: false,
  activities: [],
};

export default function chauffeur(state = initialState, action) {
  switch (action.type) {
    case chauffeurConstants.CHAUFFEUR_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case chauffeurConstants.GET_CHAUFFEUR_STARTED:
      return { ...state, isFetchingChauffeur: true };
    case chauffeurConstants.GET_CHAUFFEUR_FAILURE:
      return {
        ...state,
        isFetchingChauffeur: false,
        chauffeur: null,
        error: action.message || '',
      };
    case chauffeurConstants.GET_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isFetchingChauffeur: false,
        chauffeur: action.chauffeur,
      };

    case chauffeurConstants.GET_CHAUFFEURS_STARTED:
      return {
        ...state,
        isFetchingChauffeurs: true,
        isFetchingChauffeursFrom: action.from || 0,
      };
    case chauffeurConstants.GET_CHAUFFEURS_FAILURE:
      return {
        ...state,
        isFetchingChauffeurs: false,
        error: action.message || '',
      };
    case chauffeurConstants.GET_CHAUFFEURS_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurs: false,
        chauffeurs:
          action.from > 0 ? [...state.chauffeurs, ...action.chauffeurs] : action.chauffeurs,
        chauffeursLoaded:
          action.from > 0
            ? state.chauffeurs.length + action.chauffeurs.length
            : action.chauffeurs.length,
        chauffeursTotalResults: action.totalResults || 0,
      };

    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_STARTED:
      return {
        ...state,
        isFetchingExtendedChauffeurs: true,
        isFetchingExtendedChauffeursFrom: action.from || 0,
      };
    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_FAILURE:
      return {
        ...state,
        isFetchingExtendedChauffeurs: false,
        error: action.message || '',
      };
    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_SUCCESS:
      return {
        ...state,
        isFetchingExtendedChauffeurs: false,
        extendedChauffeurs:
          action.from > 0 ? [...state.extendedChauffeurs, ...action.chauffeurs] : action.chauffeurs,
        extendedChauffeursLoaded:
          action.from > 0
            ? state.extendedChauffeurs.length + action.chauffeurs.length
            : action.chauffeurs.length,
        extendedChauffeursTotalResults: action.totalResults || 0,
      };

    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
      };
    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        extendedChauffeurs: [
          ...state.extendedChauffeurs.map((extendedChauffeur) => ({
            ...extendedChauffeur,
            trips: action.chauffeursTrips.find(
              (chauffeurTrip) => chauffeurTrip.chauffeur === extendedChauffeur.id
            )?.trips,
          })),
        ],
      };

    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TIMESLOTS_STARTED:
      return {
        ...state,
        isFetchingTimeslots: true,
      };
    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TIMESLOTS_FAILURE:
      return { ...state, isFetchingTimeslots: false, error: action.message || '' };
    case chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TIMESLOTS_SUCCESS:
      return {
        ...state,
        isFetchingTimeslots: false,
        extendedChauffeurs: [
          ...state.extendedChauffeurs.map((extendedChauffeur) => ({
            ...extendedChauffeur,
            timeslots: action.chauffeurTimeslots.find(
              (chauffeurTimeslot) => chauffeurTimeslot.chauffeurId === extendedChauffeur.id
            )?.timeslots,
          })),
        ],
      };

    case tripConstants.CANCEL_TRIP_STARTED:
    case tripConstants.CONFIRM_TRIP_STARTED:
    case tripConstants.START_TRIP_STARTED:
    case tripConstants.FINISH_TRIP_STARTED:
      return {
        ...state,
        extendedChauffeurs: updateChauffeursTrips(state, action, {
          ...action.trip,
          isLoading: true,
        }),
      };
    case tripConstants.CONFIRM_TRIP_SUCCESS:
    case tripConstants.START_TRIP_SUCCESS:
    case tripConstants.FINISH_TRIP_FAILURE:
    case tripConstants.CANCEL_TRIP_FAILURE:
    case tripConstants.CONFIRM_TRIP_FAILURE:
    case tripConstants.START_TRIP_FAILURE:
      return {
        ...state,
        extendedChauffeurs: updateChauffeursTrips(state, action, {
          isLoading: false,
          ...action.trip,
        }),
      };

    case tripConstants.UPDATE_TRIP_ACTION_SUCCESS:
    case tripConstants.UPDATING_TRIP_SUCCESS:
      return {
        ...state,
        extendedChauffeurs: updateChauffeursTrips(state, action, { ...action.trip }),
      };
    case tripConstants.CANCEL_TRIP_SUCCESS:
    case tripConstants.FINISH_TRIP_SUCCESS:
      return {
        ...state,
        extendedChauffeurs: [...state.extendedChauffeurs].map((vt) => ({
          ...vt,
          trips: [...(vt?.trips || [])].filter((trip) => action.trip.id !== trip.id),
        })),
      };

    case tripConstants.CREATING_TRIP_SUCCESS:
      return {
        ...state,
        extendedChauffeurs: addChauffeursTrip(state, action.trip),
      };

    case chauffeurConstants.GET_CHAUFFEUR_TIMESLOTS_SUCCESS:
      return {
        ...state,
        extendedChauffeurs: [
          ...state.extendedChauffeurs.map((extendedChauffeur) =>
            extendedChauffeur.id === action.chauffeur.id ? action.chauffeur : extendedChauffeur
          ),
        ],
      };

    case chauffeurConstants.GET_CHAUFFEUR_TRIPS_SUCCESS:
      return {
        ...state,
        extendedChauffeurs: [
          ...state.extendedChauffeurs.map((extendedChauffeur) =>
            extendedChauffeur.id === action.chauffeur.id ? action.chauffeur : extendedChauffeur
          ),
        ],
      };

    case chauffeurConstants.SEARCH_CHAUFFEURS_STARTED:
      return {
        ...state,
        isFetchingChauffeurs: true,
        isFetchingChauffeursFrom: action.from || 0,
      };
    case chauffeurConstants.SEARCH_CHAUFFEURS_FAILURE:
      return {
        ...state,
        isFetchingChauffeurs: false,
        error: action.message || '',
      };
    case chauffeurConstants.SEARCH_CHAUFFEURS_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurs: false,
        chauffeurs:
          action.from > 0 ? [...state.chauffeurs, ...action.chauffeurs] : action.chauffeurs,
        chauffeursLoaded:
          action.from > 0
            ? state.chauffeurs.length + action.chauffeurs.length
            : action.chauffeurs.length,
        chauffeursTotalResults: action.totalResults || 0,
      };

    case chauffeurConstants.CREATING_CHAUFFEUR_STARTED:
      return { ...state, isFetchingChauffeur: true };
    case chauffeurConstants.CREATING_CHAUFFEUR_FAILURE:
      return {
        ...state,
        isFetchingChauffeur: false,
        error: action.message || '',
      };
    case chauffeurConstants.CREATING_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isFetchingChauffeur: false,
        chauffeur: action.chauffeur,
        chauffeurs: [action.chauffeur, ...state.chauffeurs],
      };

    case chauffeurConstants.UPDATING_CHAUFFEUR_STARTED:
      return { ...state, isFetchingChauffeur: true };
    case chauffeurConstants.UPDATING_CHAUFFEUR_FAILURE:
      return {
        ...state,
        isFetchingChauffeur: false,
        error: action.message || '',
      };
    case chauffeurConstants.UPDATING_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isFetchingChauffeur: false,
        chauffeur: action.chauffeur,
        chauffeurs: [...state.chauffeurs].map((chauffeur) => {
          if (action.chauffeur.id === chauffeur.id) {
            return action.chauffeur;
          }
          return chauffeur;
        }),
      };

    case chauffeurConstants.DELETE_CHAUFFEUR_STARTED:
      return { ...state, isFetchingChauffeur: true };
    case chauffeurConstants.DELETE_CHAUFFEUR_FAILURE:
      return {
        ...state,
        isFetchingChauffeur: false,
        message: action.message || '',
      };
    case chauffeurConstants.DELETE_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isFetchingChauffeur: false,
        chauffeur: action.chauffeur,
        chauffeurs: [...state.chauffeurs].filter(
          (chauffeur) => action.chauffeur.id !== chauffeur.id
        ),
      };

    case chauffeurConstants.GET_CHAUFFEUR_ACTIVITIES_STARTED:
      return { ...state, isFetchingActivities: true };
    case chauffeurConstants.GET_CHAUFFEUR_ACTIVITIES_FAILURE:
      return {
        ...state,
        isFetchingActivities: false,
        error: action.message || '',
      };
    case chauffeurConstants.GET_CHAUFFEUR_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isFetchingActivities: false,
        activities: action.chauffeurActivities,
      };

    case vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_STARTED:
      return { ...state, isAttachingChauffeur: true };
    case vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_FAILURE:
    case vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isAttachingChauffeur: false,
        extendedChauffeurs: state.extendedChauffeurs.map((chauffeur) => ({
          ...chauffeur,
          vehicles: action.extendedVehicle.chauffeurs
            .map((chauffeur) => chauffeur.id)
            .includes(chauffeur.id)
            ? [...chauffeur.vehicles, action.extendedVehicle]
            : [...chauffeur.vehicles],
        })),
      };

    case vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_STARTED:
      return { ...state, isDetachingChauffeur: true };
    case vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_FAILURE:
    case vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_SUCCESS:
      return {
        ...state,
        isDetachingChauffeur: false,
        extendedChauffeurs: state.extendedChauffeurs.map((chauffeur) => ({
          ...chauffeur,
          vehicles: action.extendedVehicle.chauffeurs
            .map((chauffeur) => chauffeur.id)
            .includes(chauffeur.id)
            ? [...chauffeur.vehicles].filter((vehicle) => vehicle.id !== action.extendedVehicle?.id)
            : [...chauffeur.vehicles],
        })),
      };

    case tripConstants.CREATING_TRIP_SUCCESS:
      return {
        ...state,
        extendedChauffeurs: addChauffeursTrip(state, action.trip),
      };
    case socketConstants.NEW_TRIP:
      return {
        ...state,
        extendedChauffeurs: updateChauffeursTrips(state, action, { ...action.trip }),
      };

    default:
      return state;
  }
}

const addChauffeursTrip = (state, newTrip) => {
  return [...state.extendedChauffeurs].map((vt) => ({
    ...vt,
    trips: (vt?.trips || [])?.map((trip) => trip.id).includes(newTrip.id)
      ? (vt?.trips || [])?.map((trip) => (trip.id === newTrip.id ? { ...newTrip } : trip))
      : newTrip.chauffeurs?.map((chauffeur) => chauffeur?.id)?.includes(vt.id)
      ? [...(vt?.trips || []), newTrip]
      : [...(vt.trips || [])],
  }));
};

const updateChauffeursTrips = (state, action, newTrip) => {
  // just replace
  return [...state.extendedChauffeurs].map((vt) => {
    return {
      ...vt,
      trips: action.isWithinViewFilters
        ? (vt?.trips || [])?.map((trip) => trip.id).includes(newTrip.id)
          ? (vt?.trips || [])?.map((trip) => (trip.id === newTrip.id ? { ...newTrip } : trip))
          : newTrip.chauffeurs?.map((chauffeur) => chauffeur?.entity?.id)?.includes(vt.id)
          ? [...(vt?.trips || []), newTrip]
          : [...(vt.trips || [])]
        : [...(vt.trips || [])]
            ?.map((trip) => {
              if (newTrip.id !== trip.id) {
                return trip;
              } else return newTrip;
            })
            .sort((a, b) => {
              if (a.startDate && b.startDate) return new Date(a.startDate) - new Date(b.startDate);
              if (a.startDate) {
                return 1;
              }
              if (b.startDate) {
                return -1;
              } else return -1;
            }),
    };
  });
};
