import React, { Component, createRef } from 'react';

import ScrollContainer from 'react-indiana-drag-scroll';
import Select from 'react-select';
import ReactSVG from 'react-svg';
import SimpleReactValidator from 'simple-react-validator';

import { BasicType, BlockManager, JsonToMjml } from 'easy-email-core';
import { EmailEditor, EmailEditorProvider } from 'easy-email-editor';
import { StandardLayout } from 'easy-email-extensions';
import mjml from 'mjml-browser';

import MessageAutomationTypeSelector from '@components/general/MessageAutomationTypeSelector';
import TriggerEventSelector from '@components/general/TriggerEventSelector';
import ChannelSelector from '@components/integration/crud/ChannelSelector';
import IntegrationInput from '@components/integration/crud/IntegrationInput';
import TriggerTypeSelector from '@components/integration/crud/TriggerTypeSelector';
import IntegrationFiltersInput from '@components/integration/filter/IntegrationFiltersInput';
import TQLInput from '@components/tql/TQLInput';

import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';
import VariableContentEditable from '@uiinputs/VariableContentEditable';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

import '@/resources/assets/easy-email-editor.css';
import 'easy-email-editor/lib/style.css';
import 'easy-email-extensions/lib/style.css';

export default class MessageAutomationForm extends Component {
  constructor(props) {
    super(props);

    this.emailEditorRef = createRef();

    this.validator = new SimpleReactValidator();
    this.state = {
      tql: false,
      localizedMessages: {},
      tab: this.props.settings?.user?.language?.localName,
      messageAutomationLanguages: [],
      contentVersion: 0,
    };
  }

  componentDidMount() {
    const {
      formMessageAutomation,
      updateTopStack,
      entityStructure,
      getEntityStructure,
      triggerEvents,
    } = this.props;
    const { channel } = formMessageAutomation?.automationConfig || {};
    this.setLocalizedTexts();

    switch (channel) {
      case 'email':
        updateTopStack({ fullWidth: true });
        break;
      default:
        updateTopStack({ fullWidth: false });
        break;
    }

    if (formMessageAutomation?.automationConfig?.triggerEvent) {
      const selectedEntity = triggerEvents.find(
        (option) => option.event === formMessageAutomation?.automationConfig?.triggerEvent
      );
      getEntityStructure(selectedEntity.entityType);
    } else if (formMessageAutomation?.automationConfig?.query) {
      const { entityType } = formMessageAutomation?.automationConfig?.query;
      getEntityStructure(entityType);
    }
    activateInputs();
  }

  componentDidUpdate(prevProps) {
    const { formMessageAutomation, updateTopStack } = this.props;
    const { channel } = formMessageAutomation?.automationConfig || {};

    if (
      prevProps.formMessageAutomation?.automationConfig?.channel !== channel &&
      channel === 'email'
    ) {
      updateTopStack({ fullWidth: true });
    } else if (
      prevProps.formMessageAutomation?.automationConfig?.channel !== channel &&
      prevProps.formMessageAutomation?.automationConfig?.channel === 'email'
    ) {
      updateTopStack({ fullWidth: false });
    }
    if (
      prevProps.formMessageAutomation?.automationConfig?.localizedTexts?.length !==
      formMessageAutomation?.automationConfig?.localizedTexts?.length
    ) {
      this.setLocalizedTexts();
    }
    if (
      JSON.stringify(prevProps.formMessageAutomation?.automationConfig?.localizedTexts) !==
      JSON.stringify(formMessageAutomation?.automationConfig?.localizedTexts)
    ) {
      this.setLocalizedTexts();
    }

    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(emailEditorValues) {
    const { tab, localizedMessages } = this.state;
    const { onSubmit, formMessageAutomation, setHasChanged, languages } = this.props;

    const newLocalizedMessages = { ...localizedMessages };

    if (formMessageAutomation?.automationConfig?.channel === 'email') {
      const lngCode = languages.filter((lng) => lng.localName === tab)[0]?.code;
      if (newLocalizedMessages?.lngCode) {
        newLocalizedMessages[lngCode].content = this.exportHtml(emailEditorValues);
        newLocalizedMessages[lngCode].messageTemplate = emailEditorValues;
      } else {
        newLocalizedMessages[lngCode] = {
          ...newLocalizedMessages[lngCode],
          content: this.exportHtml(emailEditorValues),
          messageTemplate: emailEditorValues,
        };
      }
    }

    const localizedTexts = this.getLocalizedTexts(newLocalizedMessages);

    const newMessageAutomation = { ...formMessageAutomation };
    const newMessageAutomationConfig = { ...formMessageAutomation.automationConfig };

    newMessageAutomationConfig.localizedTexts = localizedTexts;

    newMessageAutomation.automationConfig = newMessageAutomationConfig;

    onSubmit && onSubmit(newMessageAutomation);
    setHasChanged(false);
  }

  exportHtml = (values) => {
    return mjml(
      JsonToMjml({
        data: values.content,
        mode: 'production',
        context: values.content,
        // dataSource: mergeTags,
      }),
      {
        validationLevel: 'soft',
      }
    ).html;
  };

  exportMJML = (values) => {
    return JsonToMjml({
      data: values.content,
      mode: 'production',
      context: values.content,
      // dataSource: mergeTags,
    });
  };

  getVariableContentEditableValue = (language) => {
    const { languages } = this.props;
    const { localizedMessages } = this.state;

    const lngCode = languages.filter((lng) => lng.localName === language)[0]?.code;

    return localizedMessages?.[lngCode]?.content;
  };

  getEmailContent = () => {
    const { languages } = this.props;

    const { tab, localizedMessages } = this.state;
    const lngCode = languages.filter((lng) => lng.localName === tab)[0]?.code;

    return localizedMessages?.[lngCode]?.messageTemplate
      ? typeof localizedMessages?.[lngCode]?.messageTemplate === 'string'
        ? JSON.parse(localizedMessages?.[lngCode]?.messageTemplate)
        : localizedMessages?.[lngCode]?.messageTemplate
      : { content: BlockManager.getBlockByType(BasicType.PAGE).create({}) };
  };

  getSubject = (language) => {
    const { languages } = this.props;
    const { localizedMessages } = this.state;

    const lngCode = languages.filter((lng) => lng.localName === language)[0]?.code;

    return localizedMessages?.[lngCode]?.subject;
  };

  getLocalizedTexts = (localizedMessages) => {
    const { languages } = this.props;
    const localizedTexts = [];

    Object.keys(localizedMessages).forEach((key) => {
      const language = languages.find((lng) => lng.code === key);
      localizedTexts.push({
        language: language,
        subject: localizedMessages[key]?.subject,
        content: localizedMessages[key]?.content,
        messageTemplate: localizedMessages[key]?.messageTemplate
          ? JSON.stringify(localizedMessages[key]?.messageTemplate)
          : JSON.stringify({ content: BlockManager.getBlockByType(BasicType.PAGE).create({}) }),
      });
    });

    return localizedTexts;
  };

  setLocalizedTexts = () => {
    const localizedMessages = {};
    const { languages, formMessageAutomation } = this.props;
    const localizedTextsLanguages = [];

    const localizedTexts = formMessageAutomation?.automationConfig?.localizedTexts;

    localizedTexts?.map((text) => {
      localizedTextsLanguages.push(text?.language?.code);
      localizedMessages[text?.language?.code] = {
        subject: text?.subject,
        content: text?.content,
        messageTemplate: text?.messageTemplate
          ? JSON.parse(text?.messageTemplate)
          : { content: BlockManager.getBlockByType(BasicType.PAGE).create({}) },
      };
    });

    // if (localizedTextsLanguages.length === 0) {
    //   localizedTextsLanguages.push(this.props.settings?.user?.language?.code);
    // }

    this.setState({
      localizedMessages: localizedMessages,
      tab: languages.filter((lng) => lng.code === localizedTextsLanguages?.[0] || '')?.[0]
        ?.localName,
      messageAutomationLanguages: localizedTextsLanguages,
    });
  };

  updateLanguageObject(languageCodes) {
    const { localizedMessages } = this.state;
    if (languageCodes.length === 0) {
      return {};
    }

    const newObject = {};
    languageCodes.forEach((code) => {
      if (!code in localizedMessages) {
        newObject[code] = { content: BlockManager.getBlockByType(BasicType.PAGE).create({}) };
      } else {
        newObject[code] = localizedMessages[code];
      }
    });

    return newObject;
  }

  render() {
    const {
      t,
      darkMode,
      onChange,
      formMessageAutomation,
      messageAutomationChannels,
      dataTypes,
      fields,
      isFetchingFields,
      getFieldsRequest,
      searchFieldsRequest,
      entityStructure,
      getEntityStructure,
      languages,
      setHasChanged,
      isMessageAutomationTemplate,
    } = this.props;

    const { tql, tab, localizedMessages, messageAutomationLanguages } = this.state;

    const integrationSchedulerScheduleTypeOptions = [
      {
        value: 'seconds',
        label: t('schedulerScheduleType.seconds'),
      },
      {
        value: 'minutes',
        label: t('schedulerScheduleType.minutes'),
      },
      {
        value: 'hours',
        label: t('schedulerScheduleType.hours'),
      },
      {
        value: 'days',
        label: t('schedulerScheduleType.days'),
      },
    ];

    const languageOptions = languages
      ? languages?.map((language) => {
          return {
            label: language.localName,
            value: language.id,
            code: language.code,
          };
        })
      : [];

    const width = window.fullWidth;
    const smallScene = width < 1450;

    // Determine if the current tab is the first one
    const firstTabLanguageName = languages.find(
      (lng) => lng.code === messageAutomationLanguages[0]
    )?.localName;
    const isOnFirstTab = this.state.tab === firstTabLanguageName;

    return (
      <div
        className={`step-form messageAutomation ${
          formMessageAutomation?.automationConfig?.channel === 'email' ? ' wide' : ''
        }`}
        key={formMessageAutomation.id}
      >
        <EmailEditorProvider
          key={`${tab}-${this.state.contentVersion}`}
          autoComplete
          dashed={false}
          mergeTags={entityStructure}
          data={this.getEmailContent()}
          height={`calc(100vh - 100px)`}
          onSubmit={(values) => {
            this.handleSubmit(values);
          }}
        >
          {({ values }, { submit }) => {
            return (
              <form
                className="form active no-padding-top"
                onSubmit={(e) => {
                  e.preventDefault();

                  submit();
                }}
              >
                <div
                  className={`input-container${
                    formMessageAutomation?.automationConfig?.channel === 'email' ? ' wide' : ''
                  }`}
                >
                  <div>
                    <FormInput
                      type="text"
                      setHasChanged={setHasChanged}
                      label="form.label.name"
                      required={true}
                      value={formMessageAutomation.name}
                      onChange={(event) => {
                        const newMessageAutomation = { ...formMessageAutomation };
                        newMessageAutomation.name = event.target.value;

                        onChange('formMessageAutomation', newMessageAutomation, event);
                      }}
                    />
                    {this.validator.message(
                      t('form.label.name'),
                      formMessageAutomation.name,
                      'required'
                    )}
                  </div>

                  {isMessageAutomationTemplate && (
                    <div>
                      <FormInput
                        type="text"
                        setHasChanged={setHasChanged}
                        required={true}
                        label="form.label.category"
                        value={formMessageAutomation.templateCategory}
                        onChange={(event) => {
                          const newMessageAutomation = { ...formMessageAutomation };
                          newMessageAutomation.templateCategory = event.target.value;
                          newMessageAutomation.template = true;

                          onChange('formMessageAutomation', newMessageAutomation, event);
                        }}
                      />
                      {this.validator.message(
                        t('form.label.category'),
                        formMessageAutomation.templateCategory,
                        'required'
                      )}
                    </div>
                  )}

                  <FormInput
                    type="textarea"
                    setHasChanged={setHasChanged}
                    label="form.label.description"
                    required={true}
                    className="small"
                    value={formMessageAutomation.description}
                    onChange={(event) => {
                      const newMessageAutomation = { ...formMessageAutomation };
                      newMessageAutomation.description = event.target.value;

                      onChange('formMessageAutomation', newMessageAutomation, event);
                    }}
                  />

                  <div className="input-group">
                    <MessageAutomationTypeSelector
                      key={formMessageAutomation.type}
                      value={formMessageAutomation.type}
                      placeholder={t('form.label.selectMessageAutomationType')}
                      onChange={(e) => {
                        const newMessageAutomation = { ...formMessageAutomation };
                        newMessageAutomation.type = e.value;

                        onChange('formMessageAutomation', newMessageAutomation, e);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <TriggerTypeSelector
                      key={formMessageAutomation?.automationConfig?.triggerType}
                      value={formMessageAutomation?.automationConfig?.triggerType}
                      placeholder={t('form.label.selectTriggerType')}
                      onChange={(event) => {
                        const newMessageAutomationConfig = {
                          ...formMessageAutomation.automationConfig,
                        };
                        newMessageAutomationConfig.triggerType = event.value;
                        onChange('formMessageAutomation', {
                          ...formMessageAutomation,
                          automationConfig: newMessageAutomationConfig,
                        });
                      }}
                    />
                  </div>
                  {['trigger', 'scheduler'].includes(
                    formMessageAutomation?.automationConfig?.triggerType
                  ) && (
                    <div>
                      <div className="input-group half">
                        <FormInput
                          type="number"
                          wrapperClass="no-margin-top"
                          setHasChanged={setHasChanged}
                          label="form.label.value"
                          value={
                            formMessageAutomation?.automationConfig?.schedule
                              ? formMessageAutomation.automationConfig.schedule
                                  .integrationSchedulerScheduleValue
                              : null
                          }
                          onChange={(event) => {
                            let newMessageAutomationConfig = {
                              ...formMessageAutomation.automationConfig,
                            };
                            newMessageAutomationConfig = {
                              ...newMessageAutomationConfig,
                              schedule: {
                                ...newMessageAutomationConfig.schedule,
                                integrationSchedulerScheduleValue: event.target.value,
                              },
                            };

                            onChange('formMessageAutomation', {
                              ...formMessageAutomation,
                              automationConfig: newMessageAutomationConfig,
                            });
                          }}
                        />
                      </div>
                      <div className="input-group half">
                        <Select
                          placeholder={t('form.label.selectScheduleUnitType')}
                          options={integrationSchedulerScheduleTypeOptions}
                          value={integrationSchedulerScheduleTypeOptions.find(
                            (option) =>
                              option.value ===
                              (formMessageAutomation?.automationConfig?.schedule || {})
                                .integrationSchedulerScheduleType
                          )}
                          onChange={(event) => {
                            let newMessageAutomationConfig = {
                              ...formMessageAutomation.automationConfig,
                            };
                            newMessageAutomationConfig = {
                              ...newMessageAutomationConfig,
                              schedule: {
                                ...newMessageAutomationConfig.schedule,
                                integrationSchedulerScheduleType: event.value,
                              },
                            };

                            onChange('formMessageAutomation', {
                              ...formMessageAutomation,
                              automationConfig: newMessageAutomationConfig,
                            });
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {formMessageAutomation?.automationConfig?.triggerType === 'trigger' && (
                    <div className="input-group">
                      <TriggerEventSelector
                        key={formMessageAutomation?.automationConfig?.triggerEvent}
                        value={formMessageAutomation?.automationConfig?.triggerEvent}
                        placeholder={t('form.label.selectTriggerEvent')}
                        onChange={(event) => {
                          const newMessageAutomationConfig = {
                            ...formMessageAutomation.automationConfig,
                          };
                          newMessageAutomationConfig.triggerEvent = event.value;
                          event.entity && getEntityStructure(event.entity);
                          onChange('formMessageAutomation', {
                            ...formMessageAutomation,
                            automationConfig: newMessageAutomationConfig,
                          });
                        }}
                      />
                    </div>
                  )}
                  {['trigger', 'listener'].includes(
                    formMessageAutomation?.automationConfig?.triggerType
                  ) && (
                    <div className="input-group">
                      <IntegrationFiltersInput
                        key={formMessageAutomation.nonce}
                        value={
                          formMessageAutomation.automationConfig.filters
                            ? formMessageAutomation.automationConfig.filters
                            : []
                        }
                        dataTypes={[...dataTypes]}
                        onChange={(newFilters) => {
                          const newMessageAutomationConfig = {
                            ...formMessageAutomation.automationConfig,
                          };
                          newMessageAutomationConfig.filters = [...newFilters];
                          onChange('formMessageAutomation', {
                            ...formMessageAutomation,
                            automationConfig: newMessageAutomationConfig,
                          });
                        }}
                      />
                    </div>
                  )}

                  <div className={`input-group${tql ? ' list-sector' : ''}`}>
                    <div className={`flex-container justify-between`}>
                      <h2>{t('tql.module.header')}</h2>
                      <Switch
                        checked={tql}
                        onChange={() => {
                          this.setState({
                            tql: !tql,
                          });

                          const newMessageAutomationConfig = {
                            ...formMessageAutomation.automationConfig,
                          };
                          newMessageAutomationConfig.query = null;
                          onChange('formMessageAutomation', {
                            ...formMessageAutomation,
                            automationConfig: newMessageAutomationConfig,
                          });
                        }}
                      />
                    </div>
                    {tql && (
                      <TQLInput
                        value={formMessageAutomation?.automationConfig?.query}
                        getFieldsRequest={getFieldsRequest}
                        isFetchingFields={isFetchingFields}
                        onChange={(newQuery) => {
                          const newMessageAutomationConfig = {
                            ...formMessageAutomation.automationConfig,
                          };
                          newMessageAutomationConfig.query = newQuery;
                          newMessageAutomationConfig.query.entityType &&
                            getEntityStructure(newMessageAutomationConfig.query.entityType);
                          onChange('formMessageAutomation', {
                            ...formMessageAutomation,
                            automationConfig: newMessageAutomationConfig,
                          });
                        }}
                      />
                    )}
                  </div>
                  <div className="flex-container justify-between input-group ">
                    <h2 className="self-center">{t('recipient.module.header')}</h2>
                    <div className="flex-container">
                      <div style={{ lineHeight: '34px', marginRight: '10px' }}>
                        {t('form.label.toggleForAll')}
                      </div>
                      <Switch
                        style={{ float: 'right' }}
                        checked={formMessageAutomation?.automationConfig?.toggleForAll}
                        onChange={(e, value) => {
                          const newMessageAutomationConfig = {
                            ...formMessageAutomation.automationConfig,
                          };
                          newMessageAutomationConfig.toggleForAll = value;

                          onChange('formMessageAutomation', {
                            ...formMessageAutomation,
                            automationConfig: newMessageAutomationConfig,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {formMessageAutomation?.automationConfig?.toggleForAll ? (
                    <FormInput
                      type="text"
                      setHasChanged={setHasChanged}
                      label="form.label.recipients"
                      value={formMessageAutomation.automationConfig?.recipients?.join(',')}
                      onChange={(event) => {
                        const newMessageAutomationConfig = {
                          ...formMessageAutomation.automationConfig,
                        };
                        newMessageAutomationConfig.recipients = event.target.value.split(',');

                        onChange('formMessageAutomation', {
                          ...formMessageAutomation,
                          automationConfig: newMessageAutomationConfig,
                        });
                      }}
                    />
                  ) : (
                    <FormInput
                      type="text"
                      setHasChanged={setHasChanged}
                      label="form.label.recipientSelector"
                      value={formMessageAutomation.automationConfig?.recipientSelector}
                      onChange={(event) => {
                        const newMessageAutomationConfig = {
                          ...formMessageAutomation.automationConfig,
                        };
                        newMessageAutomationConfig.recipientSelector = event.target.value;

                        onChange('formMessageAutomation', {
                          ...formMessageAutomation,
                          automationConfig: newMessageAutomationConfig,
                        });
                      }}
                    />
                  )}

                  <div className="input-group">
                    <ChannelSelector
                      key={formMessageAutomation?.automationConfig?.channel}
                      value={formMessageAutomation?.automationConfig?.channel}
                      placeholder={t('form.label.channel')}
                      onChange={(event) => {
                        const newMessageAutomationConfig = {
                          ...formMessageAutomation.automationConfig,
                        };
                        newMessageAutomationConfig.channel = event.value;
                        newMessageAutomationConfig.message = '';
                        newMessageAutomationConfig.subject = '';
                        onChange('formMessageAutomation', {
                          ...formMessageAutomation,
                          automationConfig: newMessageAutomationConfig,
                        });
                      }}
                    />
                  </div>

                  <FormInput
                    type="text"
                    setHasChanged={setHasChanged}
                    label="form.label.languageSelector"
                    value={formMessageAutomation.automationConfig?.languageSelector}
                    onChange={(event) => {
                      const newMessageAutomationConfig = {
                        ...formMessageAutomation.automationConfig,
                      };
                      newMessageAutomationConfig.languageSelector = event.target.value;

                      onChange('formMessageAutomation', {
                        ...formMessageAutomation,
                        automationConfig: newMessageAutomationConfig,
                      });
                    }}
                  />

                  <div className="input-group">
                    <Select
                      isMulti={false}
                      value={languageOptions.find(
                        (lng) =>
                          lng.value === formMessageAutomation.automationConfig?.defaultLanguageId
                      )}
                      options={languageOptions}
                      onChange={(event) => {
                        const newMessageAutomationConfig = {
                          ...formMessageAutomation.automationConfig,
                        };
                        newMessageAutomationConfig.defaultLanguageId = event.value;

                        onChange('formMessageAutomation', {
                          ...formMessageAutomation,
                          automationConfig: newMessageAutomationConfig,
                        });
                      }}
                      placeholder={t('form.label.defaultMessageAutomationLanguage')}
                    />
                  </div>

                  <div className="input-group">
                    <Select
                      isMulti={true}
                      value={messageAutomationLanguages.map((code) =>
                        languageOptions.find((option) => option.code === code)
                      )}
                      options={languageOptions}
                      onChange={(event) => {
                        const selectedLanguages = event.map((lng) => lng.code); // This preserves the selection order
                        this.setState({
                          tab: languages.filter(
                            (lng) => lng.code === selectedLanguages[selectedLanguages.length - 1]
                          )?.[0]?.localName,
                          messageAutomationLanguages: selectedLanguages,
                          localizedMessages: this.updateLanguageObject(selectedLanguages),
                        });
                      }}
                      placeholder={t('form.label.languages')}
                    />
                  </div>
                  {formMessageAutomation?.automationConfig?.channel &&
                    !['chat', 'notification'].includes(
                      formMessageAutomation?.automationConfig?.channel
                    ) && (
                      <div className="input-group">
                        <IntegrationInput
                          value={formMessageAutomation?.automationConfig?.manualIntegration}
                          searchParameters={{ types: ['manual'] }}
                          onChange={(newIntegration) => {
                            const newMessageAutomationConfig = {
                              ...formMessageAutomation.automationConfig,
                            };
                            newMessageAutomationConfig.manualIntegration = newIntegration;
                            onChange('formMessageAutomation', {
                              ...formMessageAutomation,
                              automationConfig: newMessageAutomationConfig,
                            });
                          }}
                        />
                      </div>
                    )}

                  <>
                    <div className="input-group">
                      <b>{t('form.label.messageTemplate')}</b>
                    </div>
                    <ScrollContainer
                      key={`languages-${messageAutomationLanguages.length}`}
                      verticle={false}
                      hideScrollbars={false}
                      className="align-items-center d-flex gap-10 tab-links-container my-10"
                    >
                      {messageAutomationLanguages.map((language) => {
                        const lngName = languages.filter((lng) => lng.code === language)?.[0]
                          ?.localName;

                        return (
                          <div
                            className={`tab-links dark ${tab === lngName ? 'tab-active' : ''}`}
                            onClick={() => {
                              if (tab !== lngName) {
                                this.setState((prevState) => ({
                                  tab: lngName,
                                  contentVersion: prevState.contentVersion + 1,
                                }));

                                if (formMessageAutomation?.automationConfig?.channel === 'email') {
                                  const newLocalizedMessages = { ...localizedMessages };

                                  const lngCode = languages.filter(
                                    (lng) => lng.localName === tab
                                  )[0]?.code;
                                  if (newLocalizedMessages?.lngCode) {
                                    newLocalizedMessages[lngCode].content = this.exportHtml(values);
                                    newLocalizedMessages[lngCode].messageTemplate =
                                      JSON.stringify(values);
                                  } else {
                                    newLocalizedMessages[lngCode] = {
                                      ...newLocalizedMessages[lngCode],
                                      content: this.exportHtml(values),
                                      messageTemplate: JSON.stringify(values),
                                    };
                                  }
                                  this.setState({ localizedMessages: newLocalizedMessages });
                                }
                              }
                            }}
                          >
                            {lngName}
                          </div>
                        );
                      })}
                    </ScrollContainer>
                    {messageAutomationChannels.find(
                      (wc) => wc.type === formMessageAutomation.automationConfig?.channel
                    )?.needsSubject && (
                      <div>
                        <FormInput
                          key={tab}
                          type="text"
                          className="mb-5"
                          setHasChanged={setHasChanged}
                          label="form.label.subject"
                          value={this.getSubject(tab)}
                          onChange={(event) => {
                            const newLocalizedMessages = { ...localizedMessages };

                            const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                              ?.code;
                            if (newLocalizedMessages?.lngCode) {
                              newLocalizedMessages[lngCode].subject = event.target.value;
                            } else {
                              newLocalizedMessages[lngCode] = {
                                ...newLocalizedMessages[lngCode],
                                subject: event.target.value,
                              };
                            }
                            this.setState({ localizedMessages: newLocalizedMessages });
                          }}
                        />
                        {this.validator.message(
                          t('form.label.subject'),
                          this.getSubject(tab),
                          'required'
                        )}
                      </div>
                    )}

                    {!isOnFirstTab && (
                      <div
                        className="input-group no-margin-top d-flex justify-content-end as-link"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          const { localizedMessages } = this.state;

                          const lngCode = languages.filter((lng) => lng.localName === tab)[0]?.code;

                          const newObject = { ...localizedMessages };
                          newObject[lngCode] = localizedMessages[messageAutomationLanguages[0]];

                          const newLocalizedMessages = { ...newObject };
                          this.setState((prevState) => ({
                            localizedMessages: newLocalizedMessages,
                            contentVersion: prevState.contentVersion + 1,
                          }));
                        }}
                        title={t('form.copy')}
                      >
                        <ReactSVG src="/icons/duplicate.svg" />
                      </div>
                    )}

                    {messageAutomationLanguages.length > 0 && (
                      <>
                        {formMessageAutomation?.automationConfig?.channel === 'email' ? (
                          <div className={`arco${darkMode ? 'arco-dark' : ''} input-group`}>
                            <StandardLayout
                              key={`${tab}-${this.state.contentVersion}`}
                              compact={!smallScene}
                              showSourceCode={false}
                            >
                              <EmailEditor />
                            </StandardLayout>
                          </div>
                        ) : (
                          <VariableContentEditable
                            key={`${tab}-${this.state.contentVersion}`}
                            value={this.getVariableContentEditableValue(tab)}
                            fields={fields}
                            entityType={formMessageAutomation?.automationConfig?.query?.entityType}
                            formMessageAutomation={formMessageAutomation}
                            searchFieldsRequest={searchFieldsRequest}
                            onChange={(newValue) => {
                              const newLocalizedMessages = { ...localizedMessages };

                              const lngCode = languages.filter((lng) => lng.localName === tab)[0]
                                ?.code;
                              if (newLocalizedMessages?.lngCode) {
                                newLocalizedMessages[lngCode].content = newValue;
                              } else {
                                newLocalizedMessages[lngCode] = {
                                  ...newLocalizedMessages[lngCode],
                                  content: newValue,
                                };
                              }
                              this.setState({ localizedMessages: newLocalizedMessages });
                            }}
                          />
                        )}
                      </>
                    )}
                  </>

                  <div className="input-group">
                    <div>{t('form.label.offOn')}</div>
                    <Switch
                      checked={formMessageAutomation.enabled}
                      onChange={(e, newState) => {
                        const newMessageAutomation = { ...formMessageAutomation };
                        newMessageAutomation.enabled = newState;

                        onChange('formMessageAutomation', newMessageAutomation, e);
                      }}
                    />
                  </div>
                  <div className="input-group more right">
                    <input
                      type="submit"
                      disabled={!this.validator.allValid()}
                      onClick={(e) => {
                        e.preventDefault();
                        submit();
                      }}
                      value={t('form.save')}
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </EmailEditorProvider>
      </div>
    );
  }
}
