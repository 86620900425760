import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactSVG from 'react-svg';

import TripContentContainer from '@containers/trip/TripContentContainer';
import UpdateTripContainer from '@containers/trip/crud/UpdateTripContainer';

import Loader from '@uicomponents/Loader';
import OptionsMenu from '@uicomponents/OptionsMenu';

import Alert from '@models/Alert';

class TripView extends Component {
  static defaultProps = {
    isPublic: false,
    tabbedView: false,
    standalone: false,
  };

  state = {
    tab: 'information',
  };

  _renderTabs() {
    const { t } = this.props;
    const { tab } = this.state;
    return (
      <ScrollContainer
        verticle={false}
        hideScrollbars={false}
        className="align-items-center d-flex gap-10 tab-links-container light"
      >
        <div
          className={`tab-links smaller dark${tab === 'information' ? ' tab-active' : ''}`}
          onClick={() => this.setState({ tab: 'information' })}
        >
          {t('trip.tabs.information')}
        </div>
        <div
          className={`tab-links smaller dark${tab === 'actions' ? ' tab-active' : ''}`}
          onClick={() => this.setState({ tab: 'actions' })}
        >
          {t('trip.tabs.actions')}
        </div>
        <div
          className={`tab-links  smaller dark${tab === 'route-breakdown' ? ' tab-active' : ''}`}
          onClick={() => this.setState({ tab: 'route-breakdown' })}
        >
          {t('trip.tabs.route-breakdown')}
        </div>
        <div
          className={`tab-links smaller dark ${tab === 'todo' ? ' tab-active' : ''}`}
          onClick={() => this.setState({ tab: 'todo' })}
        >
          {t('todo.entityView.title')}
        </div>
        <div
          className={`tab-links smaller dark ${tab === 'ledger' ? ' tab-active' : ''}`}
          onClick={() => this.setState({ tab: 'ledger' })}
        >
          {t('ledger')}
        </div>
      </ScrollContainer>
    );
  }

  render() {
    const {
      t,
      trip,
      isPublic,
      tabbedView,
      standalone,
      platform,
      createAlert,
      addToStack,
      isFetchingTrip,
      cancelTrip,
      getExtendedVehicle,
    } = this.props;

    const { tab } = this.state;

    if (!trip || isFetchingTrip) {
      return <Loader />;
    }

    const isCancelled = trip.status === 'cancelled';

    let optionsMenuItems = [
      {
        name: t('form.edit'),
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();

          addToStack({
            name: trip.name,
            component: <UpdateTripContainer trip={trip} />,
          });
        },
      },
    ];

    if (!isPublic && !isCancelled) {
      optionsMenuItems.push({
        name: t('form.cancel'),
        onClick: (e) => {
          e.preventDefault();
          e.stopPropagation();

          cancelTrip(trip).then(() => {
            if (trip.vehicle) {
              getExtendedVehicle(trip.vehicle.entity.id);
            }
          });
        },
      });
    }

    return (
      <div className={`trip-view${tabbedView ? ' tabbed' : ''}`}>
        {tabbedView && this._renderTabs()}

        {(!tabbedView || tab === 'information') && (
          <div className="information">
            <div className="flex-container justify-between no-wrap">
              <div>
                <div className="flex-container justify-between">
                  <h1 className="no-margin">{trip.name}</h1>
                </div>
                <h3 className="no-margin-top">#{trip.tripNr}</h3>
              </div>

              <div className="self-center flex-container action-icons">
                <div
                  className="action-icon margin"
                  onClick={(e) => {
                    e.preventDefault();

                    try {
                      navigator.clipboard.writeText(
                        `${platform?.hostNames?.[0]?.name || window?.location?.origin}/trips/${
                          trip.id
                        }/share/${trip.shareSettings.shareCode}`
                      );

                      createAlert(
                        new Alert(
                          t('notification.copy.success.title'),
                          t('notification.copy.success.description'),
                          'success'
                        )
                      );
                    } catch (e) {
                      createAlert(
                        new Alert(
                          t('notification.copy.error.title'),
                          t('notification.copy.error.description'),
                          'error'
                        )
                      );
                    }
                  }}
                >
                  <ReactSVG src="/icons/link.svg" />
                </div>
                {!isPublic && <OptionsMenu className="self-center" items={optionsMenuItems} />}
              </div>
            </div>
          </div>
        )}

        <TripContentContainer
          key={trip.id + trip.updatedAt + trip.nonce}
          trip={trip}
          tab={tab}
          isPublic={isPublic}
          tabbedView={tabbedView}
          standalone={standalone}
          vehicle={trip?.vehicle?.entity}
          latLonPointGeoReference={trip.vehicle?.entity?.latestLocation?.geoReference}
        />
      </div>
    );
  }
}
export default withTranslation('translation')(TripView);
