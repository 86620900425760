import { socketConstants, tripConstants } from '@constants';

import moment from 'moment';

import { updateTripActionInList, updateTripInList, updateTripsInList } from '@utils/tripUtils';

const initialState = {
  isFetchingTrip: false,
  formTrip: null,
  trip: null,

  isFetchingTrips: false,
  isFetchingTripsFrom: 0,
  tripsTotalResults: 0,
  tripsLoaded: 0,
  trips: [],
  lastUpdatedTrips: moment(),

  dragTrip: null,
};

export default function trip(state = initialState, action) {
  switch (action.type) {
    case tripConstants.TRIP_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case tripConstants.GET_TRIP_STARTED:
      return { ...state, isFetchingTrip: true };
    case tripConstants.GET_TRIP_FAILURE:
      return { ...state, isFetchingTrip: false, error: action.message || '' };
    case tripConstants.GET_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
      };

    case tripConstants.GET_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
        isFetchingTripsFrom: action.from,
      };
    case tripConstants.GET_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case tripConstants.GET_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        trips: action.from > 0 ? [...state.trips, ...action.trips] : action.trips,
        tripsLoaded:
          action.from > 0 ? state.trips.length + action.trips.length : action.trips.length,
        tripsTotalResults: action.totalResults || 0,
      };

    case tripConstants.SEARCH_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
        isFetchingTripsFrom: action.from,
      };
    case tripConstants.SEARCH_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case tripConstants.SEARCH_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        trips: action.from > 0 ? [...state.trips, ...action.trips] : action.trips,
        tripsLoaded:
          action.from > 0 ? state.trips.length + action.trips.length : action.trips.length,
        tripsTotalResults: action.totalResults || 0,
      };

    case tripConstants.CREATING_TRIP_STARTED:
      return { ...state, isFetchingTrip: true };
    case tripConstants.CREATING_TRIP_FAILURE:
      return { ...state, isFetchingTrip: false, error: action.message || '' };
    case tripConstants.CREATING_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: state.trips.map((trip) => trip.id).includes(action.trip.id)
          ? updateTripInList(state, action.trip)
          : [...state.trips, action.trip],
      };

    case tripConstants.CREATING_TRIPS_STARTED:
      return { ...state, isFetchingTrips: true };
    case tripConstants.CREATING_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case tripConstants.CREATING_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        trips: action.trips,
      };

    case tripConstants.UPDATING_TRIP_STARTED:
      return { ...state, isFetchingTrip: true };
    case tripConstants.UPDATING_TRIP_FAILURE:
      return { ...state, isFetchingTrip: false, error: action.message || '' };
    case tripConstants.UPDATING_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: [...state.trips].map((trip) => {
          if (action.trip.id === trip.id) {
            return action.trip;
          }
          return trip;
        }),
      };

    case tripConstants.UPDATING_TRIPS_STARTED:
      return { ...state, isFetchingTrips: true };
    case tripConstants.UPDATING_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case tripConstants.UPDATING_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        trips: updateTripsInList(state, action.trips),
      };

    case tripConstants.DELETE_TRIP_STARTED:
      return { ...state, isFetchingTrip: true };
    case tripConstants.DELETE_TRIP_FAILURE:
      return { ...state, isFetchingTrip: false, message: action.message || '' };
    case tripConstants.DELETE_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: [...state.trips].filter((trip) => action.trip.id !== trip.id),
      };

    case tripConstants.CONFIRM_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.CONFIRM_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.CONFIRM_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.CONFIRM_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
      };
    case tripConstants.CONFIRM_TRIPS_FAILURE:
      return {
        ...state,
        isFetchingTrips: false,
        message: action.message || '',
      };
    case tripConstants.CONFIRM_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        trips: updateTripsInList(state, action.trips),
      };

    case tripConstants.CANCEL_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
      };
    case tripConstants.CANCEL_TRIPS_FAILURE:
      return {
        ...state,
        isFetchingTrips: false,
        message: action.message || '',
      };
    case tripConstants.CANCEL_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        trips: updateTripsInList(state, action.trips),
      };

    case tripConstants.START_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.START_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.START_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip?.id === state.trip?.id ? { ...action.trip } : state.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.CANCEL_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.CANCEL_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.CANCEL_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip?.id === state.trip?.id ? { ...action.trip } : state.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.FINISH_TRIP_STARTED:
      return {
        ...state,
        isFetchingTrip: true,
        trips: updateTripInList(state, { isLoading: true, ...action.trip }),
      };
    case tripConstants.FINISH_TRIP_FAILURE:
      return {
        ...state,
        isFetchingTrip: false,
        trips: updateTripInList(state, { isLoading: false, ...action.trip }),
        message: action.message || '',
      };
    case tripConstants.FINISH_TRIP_SUCCESS:
      return {
        ...state,
        isFetchingTrip: false,
        trip: action.trip?.id === state.trip?.id ? { ...action.trip } : state.trip,
        trips: updateTripInList(state, action.trip),
      };

    case tripConstants.UPDATE_TRIP_ACTION_STARTED:
      return {
        ...state,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { isLoading: true, ...action.action }
        ),
      };
    case tripConstants.UPDATE_TRIP_ACTION_SUCCESS:
    case tripConstants.UPDATE_TRIP_ACTION_FAILURE:
      return {
        ...state,
        trip: action.trip?.id === state.trip?.id ? { ...action.trip } : state.trip,
        trips: updateTripActionInList(
          state,
          { ...action.trip },
          { isLoading: false, ...action.action }
        ),
      };

    case socketConstants.NEW_TRIP:
      return {
        ...state,
        trip: action.value?.id === state.trip?.id ? { ...action.value } : state.trip,
        trips: action.isWithinViewFilters
          ? state.trips.map((trip) => trip.id).includes(action.value?.id)
            ? updateTripInList(state, action.value)
            : [...state.trips, action.value]
          : [...state.trips].filter((trip) => action.value?.id !== trip.id),
      };

    default:
      return state;
  }
}
