import { transportEquipmentConstants, tripConstants } from '@constants';

import moment from 'moment';

const initialState = {
  isFetchingTransportEquipment: false,
  formTransportEquipment: null,
  transportEquipment: null,
  isFetchingTrips: true,

  isFetchingTransportEquipments: false,
  isFetchingTransportEquipmentsFrom: 0,
  transportEquipmentsTotalResults: 0,
  transportEquipmentsLoaded: 0,
  transportEquipments: [],

  isFetchingExtendedTransportEquipments: false,
  isFetchingExtendedTransportEquipmentsFrom: 0,
  extendedTransportEquipmentsTotalResults: 0,
  extendedTransportEquipmentsLoaded: 0,
  extendedTransportEquipments: [],

  lastUpdatedExtendedTransportEquipments: moment(),
};

export default function transportEquipment(state = initialState, action) {
  switch (action.type) {
    case transportEquipmentConstants.TRANSPORT_EQUIPMENT_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case transportEquipmentConstants.GET_TRANSPORT_EQUIPMENTS_STARTED:
      return {
        ...state,
        isFetchingTransportEquipments: true,
        isFetchingTransportEquipmentsFrom: action.from || 0,
      };
    case transportEquipmentConstants.GET_TRANSPORT_EQUIPMENTS_FAILURE:
      return {
        ...state,
        isFetchingTransportEquipments: false,
        error: action.message || '',
      };
    case transportEquipmentConstants.GET_TRANSPORT_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        isFetchingTransportEquipments: false,
        transportEquipments:
          action.from > 0
            ? [...state.transportEquipments, ...action.transportEquipments]
            : action.transportEquipments,
        transportEquipmentsLoaded:
          action.from > 0
            ? state.transportEquipments.length + action.transportEquipments.length
            : action.transportEquipments.length,
        transportEquipmentsTotalResults: action.totalResults || 0,
      };

    case transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_STARTED:
      return {
        ...state,
        isFetchingExtendedTransportEquipments: true,
        isFetchingExtendedTransportEquipmentsFrom: action.from || 0,
      };
    case transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_FAILURE:
      return {
        ...state,
        isFetchingExtendedTransportEquipments: false,
        error: action.message || '',
      };
    case transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        isFetchingExtendedTransportEquipments: false,
        extendedTransportEquipments:
          action.from > 0
            ? [...state.extendedTransportEquipments, ...action.transportEquipments]
            : action.transportEquipments,
        extendedTransportEquipmentsLoaded:
          action.from > 0
            ? state.extendedTransportEquipments.length + action.transportEquipments.length
            : action.transportEquipments.length,
        extendedTransportEquipmentsTotalResults: action.totalResults || 0,
        lastUpdatedExtendedTransportEquipments: moment(),
      };

    case transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_TRIPS_SUCCESS:
      return {
        ...state,
        extendedTransportEquipments: [
          ...state.extendedTransportEquipments.map((extendedTransportEquipment) =>
            extendedTransportEquipment.id === action.transportEquipment.id
              ? action.transportEquipment
              : extendedTransportEquipment
          ),
        ],
      };

    case transportEquipmentConstants.SEARCH_TRANSPORT_EQUIPMENTS_STARTED:
      return {
        ...state,
        isFetchingTransportEquipments: true,
        isFetchingTransportEquipmentsFrom: action.from || 0,
      };
    case transportEquipmentConstants.SEARCH_TRANSPORT_EQUIPMENTS_FAILURE:
      return {
        ...state,
        isFetchingTransportEquipments: false,
        error: action.message || '',
      };
    case transportEquipmentConstants.SEARCH_TRANSPORT_EQUIPMENTS_SUCCESS:
      return {
        ...state,
        isFetchingTransportEquipments: false,
        transportEquipments:
          action.from > 0
            ? [...state.transportEquipments, ...action.transportEquipments]
            : action.transportEquipments,
        transportEquipmentsLoaded:
          action.from > 0
            ? state.transportEquipments.length + action.transportEquipments.length
            : action.transportEquipments.length,
        transportEquipmentsTotalResults: action.totalResults || 0,
      };

    case transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_STARTED:
      return { ...state, isFetchingTransportEquipment: true };
    case transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        error: action.message || '',
      };
    case transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        transportEquipment: action.transportEquipment,
      };

    case transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_STARTED:
      return {
        ...state,
        isFetchingTrips: true,
      };
    case transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_FAILURE:
      return { ...state, isFetchingTrips: false, error: action.message || '' };
    case transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_SUCCESS:
      return {
        ...state,
        isFetchingTrips: false,
        extendedTransportEquipments: [
          ...state.extendedTransportEquipments.map((extendedTransportEquipment) => ({
            ...extendedTransportEquipment,
            trips: action.transportEquipmentTrips.find(
              (transportEquipmentTrips) =>
                transportEquipmentTrips.transportEquipment === extendedTransportEquipment.id
            )?.trips,
          })),
        ],
      };

    case tripConstants.CANCEL_TRIP_STARTED:
    case tripConstants.CONFIRM_TRIP_STARTED:
    case tripConstants.START_TRIP_STARTED:
    case tripConstants.FINISH_TRIP_STARTED:
      return {
        ...state,
        extendedTransportEquipments: updateTransportEquipmentsTrips(state, {
          ...action.trip,
          isLoading: true,
        }),
      };
    case tripConstants.CONFIRM_TRIP_SUCCESS:
    case tripConstants.START_TRIP_SUCCESS:
    case tripConstants.FINISH_TRIP_FAILURE:
    case tripConstants.CANCEL_TRIP_FAILURE:
    case tripConstants.CONFIRM_TRIP_FAILURE:
    case tripConstants.START_TRIP_FAILURE:
      return {
        ...state,
        extendedTransportEquipments: updateTransportEquipmentsTrips(state, {
          isLoading: false,
          ...action.trip,
        }),
      };

    case tripConstants.UPDATE_TRIP_ACTION_SUCCESS:
    case tripConstants.UPDATING_TRIP_SUCCESS:
      return {
        ...state,
        extendedTransportEquipments: updateTransportEquipmentsTrips(state, { ...action.trip }),
      };
    case tripConstants.CANCEL_TRIP_SUCCESS:
    case tripConstants.FINISH_TRIP_SUCCESS:
      return {
        ...state,
        extendedTransportEquipments: [...state.extendedTransportEquipments].map((vt) => ({
          ...vt,
          trips: [...(vt?.trips || [])].filter((trip) => action.trip.id !== trip.id),
        })),
      };

    case tripConstants.CREATING_TRIP_SUCCESS:
      return {
        ...state,
        extendedTransportEquipments: addTransportEquipmentsTrip(state, action.trip),
      };

    case transportEquipmentConstants.CREATING_TRANSPORT_EQUIPMENT_STARTED:
      return { ...state, isFetchingTransportEquipment: true };
    case transportEquipmentConstants.CREATING_TRANSPORT_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        error: action.message || '',
      };
    case transportEquipmentConstants.CREATING_TRANSPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        transportEquipment: action.transportEquipment,
        transportEquipments: [action.transportEquipment, ...state.transportEquipments],
      };

    case transportEquipmentConstants.UPDATING_TRANSPORT_EQUIPMENT_STARTED:
      return { ...state, isFetchingTransportEquipment: true };
    case transportEquipmentConstants.UPDATING_TRANSPORT_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        error: action.message || '',
      };
    case transportEquipmentConstants.UPDATING_TRANSPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        transportEquipment: action.transportEquipment,
        transportEquipments: [...state.transportEquipments].map((transportEquipment) => {
          if (action.transportEquipment.id === transportEquipment.id) {
            return action.transportEquipment;
          }
          return transportEquipment;
        }),
      };

    case transportEquipmentConstants.DELETE_TRANSPORT_EQUIPMENT_STARTED:
      return { ...state, isFetchingTransportEquipment: true };
    case transportEquipmentConstants.DELETE_TRANSPORT_EQUIPMENT_FAILURE:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        message: action.message || '',
      };
    case transportEquipmentConstants.DELETE_TRANSPORT_EQUIPMENT_SUCCESS:
      return {
        ...state,
        isFetchingTransportEquipment: false,
        transportEquipment: action.transportEquipment,
        transportEquipments: [...state.transportEquipments].filter(
          (transportEquipment) => action.transportEquipment.id !== transportEquipment.id
        ),
      };
    default:
      return state;
  }
}

const addTransportEquipmentsTrip = (state, newTrip) => {
  return [...state.extendedTransportEquipments].map((vt) => ({
    ...vt,
    trips: (vt?.trips || [])?.map((trip) => trip.id).includes(newTrip.id)
      ? (vt?.trips || [])?.map((trip) => (trip.id === newTrip.id ? { ...newTrip } : trip))
      : newTrip.transportEquipments
          ?.map((transportEquipment) => transportEquipment?.entity?.id)
          ?.includes(vt.id)
      ? [...(vt?.trips || []), newTrip]
      : [...(vt.trips || [])],
  }));
};

const updateTransportEquipmentsTrips = (state, action, newTrip) => {
  // just replace
  return [...state.extendedTransportEquipments].map((vt) => {
    return {
      ...vt,
      trips: action.isWithinViewFilters
        ? (vt?.trips || [])?.map((trip) => trip.id).includes(newTrip.id)
          ? (vt?.trips || [])?.map((trip) => (trip.id === newTrip.id ? { ...newTrip } : trip))
          : newTrip.transportEquipments
              ?.map((transportEquipment) => transportEquipment?.entity?.id)
              ?.includes(vt.id)
          ? [...(vt?.trips || []), newTrip]
          : [...(vt.trips || [])]
        : [...(vt.trips || [])]
            ?.map((trip) => {
              if (newTrip.id !== trip.id) {
                return trip;
              } else return newTrip;
            })
            .sort((a, b) => {
              if (a.startDate && b.startDate) return new Date(a.startDate) - new Date(b.startDate);
              if (a.startDate) {
                return 1;
              }
              if (b.startDate) {
                return -1;
              } else return -1;
            }),
    };
  });
};
