export const calculateCrow = (lat1, lon1, lat2, lon2) => {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  var newLat1 = toRad(lat1);
  var newLat2 = toRad(lat2);

  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(newLat1) * Math.cos(newLat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c;
  return d;
};

export const toRad = (Value) => {
  return (Value * Math.PI) / 180;
};

export const administrativeReferenceToString = (
  administrativeReference,
  withName = false,
  asString = false,
  location
) => {
  if (administrativeReference && asString) {
    return `${withName ? `${administrativeReference.name} - ` : ''}${`${
      administrativeReference.street || ''
    } ${administrativeReference.houseNumber || ''}${
      administrativeReference.houseNumberAddition || ''
    },${administrativeReference.postalCode ? ` ${administrativeReference.postalCode}` : ''} ${
      administrativeReference.city || ''
    }${administrativeReference.country ? `, ${administrativeReference.country}` : ''} `}`;
  } else if (administrativeReference) {
    return (
      <>
        {withName ? <b>{administrativeReference.name} - </b> : ''}
        {`${administrativeReference.street || ''} ${administrativeReference.houseNumber || ''} ${
          administrativeReference.houseNumberAddition || ''
        },${administrativeReference.postalCode ? ` ${administrativeReference.postalCode}` : ''} ${
          administrativeReference.city || ''
        }${administrativeReference.country ? `, ${administrativeReference.country}` : ''} `}
        {location?.remark && <i> - {location.remark}</i>}
      </>
    );
  } else '';
};

export const administrativeReferenceToMinimalString = (
  administrativeReference,
  withName = false,
  location
) => {
  if (administrativeReference) {
    return (
      <>
        {withName ? <b>{administrativeReference.name} - </b> : ''}
        {`${administrativeReference.city ? `${administrativeReference.city}` : ''}`}
        {`${administrativeReference.country ? ` ${administrativeReference.country}` : ''}`}
        {location?.remark && <i> - {location.remark}</i>}
      </>
    );
  } else '';
};

export const administrativeReferenceNamePostalCodeCityString = (
  administrativeReference,
  withName = false,
  location
) => {
  if (administrativeReference) {
    return (
      <>
        {withName ? <b>{administrativeReference.name} - </b> : ''}
        {administrativeReference.postalCode ? `${administrativeReference.postalCode}` : ''}
        {`${administrativeReference.city ? `, ${administrativeReference.city}` : ''}`}
        {administrativeReference.country ? `, ${administrativeReference.country}` : ''}
        {location?.remark && <i> - {location.remark}</i>}
      </>
    );
  } else '';
};

export const administrativeReferenceToSpacedString = (
  administrativeReference,
  withName = false
) => {
  if (administrativeReference) {
    return `${withName ? `${administrativeReference.name} - ` : ''}
                ${`${administrativeReference.street || ''} ${
                  administrativeReference.houseNumber || ''
                }${administrativeReference.houseNumberAddition || ''}\n ${
                  administrativeReference.postalCode ? ` ${administrativeReference.postalCode}` : ''
                } ${administrativeReference.city || ''}${
                  administrativeReference.country ? ` ${administrativeReference.country}` : ''
                } `}`;
  } else '';
};
