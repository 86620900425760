import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateTransportOrderPage from '@components/transportOrder/crud/UpdateTransportOrderPage.js';

import * as fileApi from '@api/fileApi';

import * as administrationActions from '@actions/administrationActions';
import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';
import * as transportOrderActions from '@actions/transportOrderActions';

class UpdateTransportOrderContainer extends PureComponent {
  componentDidMount() {
    const { changeValue, ownProps } = this.props;
    const { transportOrder } = ownProps;

    changeValue('formTransportOrder', transportOrder);
  }
  render() {
    const {
      callback,
      preventPop,
      changeValue,
      searchBusinesses,
      changeQuoteValue,
      updateTransportOrder,
      ...props
    } = this.props;
    const { popStack } = this.props;

    return (
      <UpdateTransportOrderPage
        {...props}
        onChange={changeValue}
        changeValue={changeQuoteValue}
        updateTransportOrder={(transportOrder) =>
          updateTransportOrder(transportOrder).then((response) => {
            !preventPop && popStack?.();
            callback?.(response);
          })
        }
        searchBusinesses={(searchParameters, from, amount) =>
          searchBusinesses(searchParameters, from, amount)
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    simpleForms: state.settings?.settings?.userInterface?.simpleForms,
    user: state.auth.user,
    currencies: state.platform.currencies,
    isFetchingCurrencies: state.platform.isFetchingCurrencies,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
    variables: state.settings.variables,

    ...state.business,
    ...state.transportOrder,
    ...state.administration,
    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeQuoteValue: (name, value) => dispatch(administrationActions.changeValue(name, value)),

    updateTransportOrder: (transportOrder) =>
      dispatch(transportOrderActions.updateTransportOrder(transportOrder)),
    changeValue: (name, value) => dispatch(transportOrderActions.changeValue(name, value)),

    searchBusinesses: (searchParameters, from, amount) =>
      dispatch(businessActions.searchBusinesses(searchParameters, from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateTransportOrderContainer));
