import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import CloseButton from '@uicomponents/CloseButton';
import Loader from '@uicomponents/Loader';

import TransportOrderForm from './TransportOrderForm';

export default class UpdateTransportOrderPage extends Component {
  componentDidMount() {
    const { onChange, formTransportOrder } = this.props;
    const newTransportOrder = { ...formTransportOrder };

    if (newTransportOrder.quote) {
      newTransportOrder.spotQuote = true;
    }

    onChange('formTransportOrder', newTransportOrder);
  }

  render() {
    const { t, formTransportOrder, isFetchingTransportOrder, updateTransportOrder, onClose } =
      this.props;

    if (!formTransportOrder || isFetchingTransportOrder) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView className="step-form transport-order-form">
        <div className="flex-container justify-content-center align-items-center">
          <h1>{t('transportOrder.update')}</h1>
          {onClose && (
            <CloseButton
              onClick={() => {
                onClose?.();
              }}
            />
          )}
        </div>
        <TransportOrderForm
          {...this.props}
          key={formTransportOrder.id}
          onSubmit={(transportOrder) => updateTransportOrder(transportOrder)}
        />
      </AsideView>
    );
  }
}
