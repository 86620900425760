import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/trip/${id}`);
};
export const publicGet = (id = '', shareCode = '') => {
  return api.get(`/trip/${id}/${shareCode}`);
};
export const create = (trip) => {
  return api.post(`/trip`, trip);
};
export const createWithRoute = (trip) => {
  return api.post(`/trip:route`, trip);
};
export const createTrips = (trips) => {
  return api.post(`/trips`, trips);
};
export const update = (trip) => {
  return api.put(`/trip`, trip);
};
export const remove = (trip) => {
  return api.delete(`/trip/${trip.id}`);
};

// Getting and search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/trips/${from}/${amount}`);
};
export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/trips/${from}/${amount}`, searchParameters);
};

// Business logic
export const confirm = (trip) => {
  return api.get(`/trip/${trip.id}/confirm`);
};
export const start = (trip) => {
  return api.get(`/trip/${trip.id}/start`);
};
export const finish = (trip) => {
  return api.get(`/trip/${trip.id}/finish`);
};
export const finishAndStart = (trip, nextTrip) => {
  return api.get(`/trip/${trip.id}/finish/${nextTrip.id}/start`);
};
export const cancel = (trip) => {
  return api.get(`/trip/${trip.id}/cancel`);
};
export const confirmTrips = (tripIds) => {
  return api.post(`/trips/confirm`, { tripIds });
};
export const cancelTrips = (tripIds) => {
  return api.post(`/trips/cancel`, { tripIds });
};
export const updateTrips = (trips) => {
  return api.put(`/trips`, trips);
};

export const updateTripAction = (trip, action) => {
  return api.put(`/trip/${trip.id}/action`, { action });
};
export const updateTripActionLifeCycle = (trip, action, lifeCycle) => {
  return api.put(`/trip/${trip.id}/action/${action.id}`, {
    lifeCycle: lifeCycle,
  });
};

export const updateTripActionTimes = (trip, action, startTime, endTime) => {
  return api.put(`/trip/${trip.id}/action/${action.id}/times`, {
    startTime,
    endTime,
  });
};

export const updateTripAddDocuments = (trip, documents) => {
  return api.put(`/trip/${trip.id}/documents`, { documents });
};

export const optimizer = (
  locations = [],
  consignments = [],
  vehicles = [],
  transportEquipments = [],
  chauffeurs = [],
  startDate
) => {
  return api.post(`/trip/optimizer`, {
    locations,
    consignments,
    vehicles,
    transportEquipments,
    chauffeurs,
    startDate,
  });
};
