import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TripContent from '@components/trip/TripContent';

import * as ledgerActions from '@actions/ledgerActions';
import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';
import * as vehicleActions from '@actions/vehicleActions';

class TripContentContainer extends PureComponent {
  render() {
    const { ...props } = this.props;
    return <TripContent {...props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',

    ...state.trip,
    ...state.ledger,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getExtendedVehicle: (id) => dispatch(vehicleActions.getExtendedVehicle(id)),

    changeValue: (name, value) => dispatch(tripActions.changeValue(name, value)),

    confirmTrip: (trip) => dispatch(tripActions.confirmTrip(trip)),
    startTrip: (trip) => dispatch(tripActions.startTrip(trip)),
    finishTrip: (trip) => dispatch(tripActions.finishTrip(trip)),
    finishAndStartNewTrip: (trip, newTrip) =>
      dispatch(tripActions.finishAndStartNewTrip(trip, newTrip)),
    cancelTrip: (trip) => dispatch(tripActions.cancelTrip(trip)),
    updateTripActionLifeCycle: (trip, action, lifeCycle) =>
      dispatch(tripActions.updateTripActionLifeCycle(trip, action, lifeCycle)),
    updateTrip: (trip) => dispatch(tripActions.updateTrip(trip)),

    createAlert: (alert) => dispatch(navigationActions.createAlert(alert)),
    getLedgerEntriesByEntity: (entity) => dispatch(ledgerActions.getLedgerEntriesByEntity(entity)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TripContentContainer));
