import React, { Component } from 'react';

import ActionIcon from './ActionIcon';

export default class ActionIcons extends Component {
  static defaultProps = {
    namespace: '',
  };
  render() {
    const { name, namespace, icons } = this.props;

    return (
      <div className="actions action-icons self-center align-items-center">
        {icons
          .filter((icon) => icon)
          .map((icon) => (
            <ActionIcon
              key={`${name}-${icon?.name}-${icon?.type}-${icon?.icon}`}
              name={name}
              namespace={namespace}
              {...icon}
            />
          ))}
      </div>
    );
  }
}
