import * as ledgerApi from '../../api/ledgerApi';
import { ledgerConstants } from '../constants';

// Fetch ledger entries per entity
export const startGetLedgerEntriesByEntity = (from) => ({
  type: ledgerConstants.GET_LEDGER_ENTRIES_BY_ENTITY_STARTED,
  from: from,
});

export const getLedgerEntriesByEntitySuccess = (result, from) => ({
  type: ledgerConstants.GET_LEDGER_ENTRIES_BY_ENTITY_SUCCESS,
  from: from,
  ...result,
});

export const getLedgerEntriesByEntityFailure = (error) => ({
  type: ledgerConstants.GET_LEDGER_ENTRIES_BY_ENTITY_FAILURE,
  ...error,
});

export const getLedgerEntriesByEntity = (entity) => {
  return (dispatch) => {
    dispatch(startGetLedgerEntriesByEntity());

    const promise = ledgerApi.getLedgerEntriesByEntity(entity);

    promise
      .then((result) => {
        dispatch(getLedgerEntriesByEntitySuccess(result));
      })
      .catch((error) => {
        dispatch(getLedgerEntriesByEntityFailure(error));
      });

    return promise;
  };
};
