import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ConsignmentDetailView from '@components/consignment/ConsignmentDetailView';

import Loader from '@uicomponents/Loader';

import * as chatActions from '@actions/chatActions';
import * as consignmentActions from '@actions/consignmentActions';
import * as ledgerActions from '@actions/ledgerActions';
import * as navigationActions from '@actions/navigationActions';

class ConsignmentDetailContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getExtendedConsignment, getChat } = this.props;
    if (ownProps.consignment) {
      changeValue('consignment', ownProps.consignment);
      if (ownProps.consignment.chat) getChat(ownProps.consignment.chat.id);
    } else if (ownProps.consignmentId) {
      getExtendedConsignment(ownProps.consignmentId).then((data) => {
        changeValue('consignment', data.consignment);
        if (data.consignment.chat) getChat(data.consignment.chat.id);
      });
    }
  }

  render() {
    const { consignment, ownProps } = this.props;

    if (
      consignment?.id !== ownProps.consignment?.id &&
      consignment?.id !== ownProps.consignmentId
    ) {
      return <Loader />;
    }

    return <ConsignmentDetailView {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,

    ...state.consignment,
    ...state.ledger,

    chatIsFetching: state.chat.chatIsFetching,
    chat: state.chat.chat,
    standalone: ownProps.standalone || false,
    ownProps: ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(consignmentActions.changeValue(name, value)),

    getExtendedConsignment: (id) => dispatch(consignmentActions.getExtendedConsignment(id)),
    deleteConsignment: (consignment) => dispatch(consignmentActions.deleteConsignment(consignment)),
    updateConsignment: (consignment) => dispatch(consignmentActions.updateConsignment(consignment)),

    getChat: (id) => dispatch(chatActions.getChat(id)),
    getLedgerEntriesByEntity: (entity) => dispatch(ledgerActions.getLedgerEntriesByEntity(entity)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ConsignmentDetailContainer));
