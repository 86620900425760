import { renderRoutes } from '@routes';
import { lazy } from 'react';

const Login = lazy(() => import('@containers/authentication/LoginContainer'));
const SSOCallback = lazy(() => import('@containers/authentication/SSOCallbackContainer'));
const ConfirmEmail = lazy(() => import('@containers/authentication/ConfirmEmailContainer'));
const Register = lazy(() => import('@containers/authentication/register/RegisterContainer'));
const RegisterDeveloper = lazy(() =>
  import('@containers/authentication/register/DeveloperRegisterContainer')
);
const RegisterSupplier = lazy(() =>
  import('@containers/authentication/register/SupplierRegisterContainer')
);
const Welcome = lazy(() => import('@containers/authentication/register/WelcomeContainer'));
const SupplierWelcome = lazy(() => import('@containers/authentication/register/SupplierWelcomeContainer'));
const DeveloperWelcome = lazy(() => import('@containers/authentication/register/DeveloperWelcomeContainer'));

const Forgot = lazy(() => import('@containers/authentication/ForgotContainer'));
const ForgotSuccess = lazy(() => import('@containers/authentication/ForgotSuccessContainer'));

const Otp = lazy(() => import('@containers/authentication/OtpContainer'));
const Recover = lazy(() => import('@containers/authentication/ForgotResetContainer'));
const Logout = lazy(() => import('@containers/authentication/LogoutContainer'));

const routes = [
  {
    paths: ['/', '/login'],
    component: Login,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/register'],
    component: Register,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/login/callback'],
    component: SSOCallback,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/developer/register'],
    component: RegisterDeveloper,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/supplier/register'],
    component: RegisterSupplier,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/forgot'],
    component: Forgot,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/forgot/success'],
    component: ForgotSuccess,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/otp'],
    component: Otp,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/user/recover/:user/:token'],
    component: Recover,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/user/:userId/verify/:token'],
    component: ConfirmEmail,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/developer/welcome/:username'],
    component: DeveloperWelcome,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/supplier/welcome/:username'],
    component: SupplierWelcome,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/welcome/:username'],
    component: Welcome,
    type: 'default',
    props: { noHeader: true, noFooter: true },
  },
  {
    paths: ['/logout'],
    component: Logout,
    type: 'open',
  },
];

const AuthRoutes = (props) => {
  return renderRoutes(props, routes);
}
export default AuthRoutes;
