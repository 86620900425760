import { socketConstants, transportOrderConstants } from '@constants';

import moment from 'moment';

import { distinct } from '@utils/arrayUtils';

const initialState = {
  isFetchingTransportOrder: false,
  formTransportOrder: null,
  transportOrder: null,

  isFetchingTransportOrders: false,
  transportOrdersTotalResults: 0,
  transportOrdersLoaded: 0,
  transportOrdesFrom: 0,
  transportOrders: [],

  isFetchingWatchTowerTransportOrders: false,
  isFetchingWatchTowerTransportOrdersFrom: 0,
  watchTowerTransportOrdersTotalResults: 0,
  watchTowerTransportOrdersLoaded: 0,
  watchTowerTransportOrders: [],

  lastUpdatedWatchTowerTransportOrders: moment(),
};

export default function transportOrder(state = initialState, action) {
  switch (action.type) {
    case transportOrderConstants.TRANSPORT_ORDER_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case transportOrderConstants.GET_TRANSPORT_ORDER_STARTED:
      return { ...state, isFetchingTransportOrder: true };
    case transportOrderConstants.GET_TRANSPORT_ORDER_FAILURE:
      return {
        ...state,
        isFetchingTransportOrder: false,
        error: action.message || '',
      };
    case transportOrderConstants.GET_TRANSPORT_ORDER_SUCCESS:
      return {
        ...state,
        isFetchingTransportOrder: false,
        transportOrder: action.transportOrder,
      };

    case transportOrderConstants.GET_TRANSPORT_ORDERS_STARTED:
      return {
        ...state,
        isFetchingTransportOrders: true,
        transportOrdersFrom: action.from || 0,
      };
    case transportOrderConstants.GET_TRANSPORT_ORDERS_FAILURE:
      return {
        ...state,
        isFetchingTransportOrders: false,
        error: action.message || '',
      };
    case transportOrderConstants.GET_TRANSPORT_ORDERS_SUCCESS:
      return {
        ...state,
        isFetchingTransportOrders: false,
        transportOrders:
          action.from > 0
            ? [...state.transportOrders, ...action.transportOrders]
            : action.transportOrders,
        transportOrdersLoaded:
          action.from > 0
            ? state.transportOrders.length + action.transportOrders.length
            : action.transportOrders.length,
        transportOrdersTotalResults: action.totalResults || 0,
      };

    case transportOrderConstants.GET_TRANSPORT_ORDERS_WATCHTOWER_STARTED:
      return {
        ...state,
        isFetchingWatchTowerTransportOrders: true,
        isFetchingWatchTowerTransportOrdersFrom: action.from || 0,
      };
    case transportOrderConstants.GET_TRANSPORT_ORDERS_WATCHTOWER_FAILURE:
      return {
        ...state,
        isFetchingWatchTowerTransportOrders: false,
        error: action.message || '',
      };
    case transportOrderConstants.GET_TRANSPORT_ORDERS_WATCHTOWER_SUCCESS:
      return {
        ...state,
        isFetchingWatchTowerTransportOrders: false,
        watchTowerTransportOrders:
          action.from > 0
            ? [...state.watchTowerTransportOrders, ...action.transportOrders]
            : action.transportOrders,
        watchTowerTransportOrdersLoaded:
          action.from > 0
            ? state.watchTowerTransportOrders.length +
              action.transportOrders.length
            : action.transportOrders.length,
        watchTowerTransportOrdersTotalResults: action.totalResults || 0,
      };

    case transportOrderConstants.CREATING_TRANSPORT_ORDER_STARTED:
      return { ...state, isFetchingTransportOrder: true };
    case transportOrderConstants.CREATING_TRANSPORT_ORDER_FAILURE:
      return {
        ...state,
        isFetchingTransportOrder: false,
        error: action.message || '',
      };
    case transportOrderConstants.CREATING_TRANSPORT_ORDER_SUCCESS:
      return {
        ...state,
        isFetchingTransportOrder: false,
        transportOrder: action.transportOrder,
        formTransportOrder: action.transportOrder,
        transportOrders: [
          action.transportOrder,
          ...state.transportOrders,
        ],
        transportOrdersLoaded: [
          action.transportOrder,
          ...state.transportOrders,
        ].length,
      };

    case transportOrderConstants.UPDATING_TRANSPORT_ORDER_STARTED:
      return { ...state, isFetchingTransportOrder: true };
    case transportOrderConstants.UPDATING_TRANSPORT_ORDER_FAILURE:
      return {
        ...state,
        isFetchingTransportOrder: false,
        error: action.message || '',
      };
    case transportOrderConstants.UPDATING_TRANSPORT_ORDER_SUCCESS:
      return {
        ...state,
        isFetchingTransportOrder: false,
        transportOrder: action.transportOrder,
        formTransportOrder: action.transportOrder,
        transportOrders: [...state.transportOrders].map((transportOrder) => {
          if (action.transportOrder.id === transportOrder.id) {
            return action.transportOrder;
          }
          return transportOrder;
        }),
      };

    case transportOrderConstants.DELETE_TRANSPORT_ORDER_STARTED:
      return { ...state, isFetchingTransportOrder: true };
    case transportOrderConstants.DELETE_TRANSPORT_ORDER_FAILURE:
      return {
        ...state,
        isFetchingTransportOrder: false,
        message: action.message || '',
      };
    case transportOrderConstants.DELETE_TRANSPORT_ORDER_SUCCESS:
      return {
        ...state,
        isFetchingTransportOrder: false,
        transportOrder: action.transportOrder,
        transportOrders: [...state.transportOrders].filter(
          (transportOrder) => action.transportOrder.id !== transportOrder.id
        ),
      };

    case socketConstants.NEW_EXTENDED_TRANSPORT_ORDERS:
      return {
        ...state,
        watchTowerTransportOrders: distinct(
          [action.value, ...state.watchTowerTransportOrders],
          'id'
        ).map((newTransportOrder) =>
          action.value?.id === newTransportOrder.id
            ? action.value
            : newTransportOrder
        ),
        lastUpdatedWatchTowerTransportOrders: moment(),
      };

    default:
      return state;
  }
}
