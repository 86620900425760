import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TransportOrderView from '@components/transportOrder/TransportOrderView';

import * as ledgerActions from '@actions/ledgerActions';
import * as navigationActions from '@actions/navigationActions';
import * as transportOrderActions from '@actions/transportOrderActions';

class TransportOrderContainer extends PureComponent {
  componentDidMount() {
    const { changeValue, getTransportOrder, ownProps } = this.props;
    if (ownProps.transportOrder) {
      changeValue('transportOrder', ownProps.transportOrder);
    } else if (ownProps.transportOrderId) {
      getTransportOrder(ownProps.transportOrderId);
    }
  }

  render() {
    const { changeValue, getTransportOrder, ...props } = this.props;
    return (
      <TransportOrderView
        {...props}
        onChange={changeValue}
        getTransportOrder={(id) => getTransportOrder(id)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    ...state.transportOrder,
    ...state.ledger,
    ownProps,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(transportOrderActions.changeValue(name, value)),
    getTransportOrder: (id) => dispatch(transportOrderActions.getTransportOrder(id)),

    acceptTransportOrder: (transportOrder) =>
      dispatch(transportOrderActions.acceptTransportOrder(transportOrder)),
    cancelTransportOrder: (transportOrder) =>
      dispatch(transportOrderActions.cancelTransportOrder(transportOrder)),
    deleteTransportOrder: (transportOrder) =>
      dispatch(transportOrderActions.deleteTransportOrder(transportOrder)),
    updateTransportOrder: (transportOrder) =>
      dispatch(transportOrderActions.updateTransportOrder(transportOrder)),

    getLedgerEntriesByEntity: (entity) => dispatch(ledgerActions.getLedgerEntriesByEntity(entity)),

    createAlert: (alert) => dispatch(navigationActions.createAlert(alert)),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
    updateTopStack: (args) => dispatch(navigationActions.updateTopStack(args)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TransportOrderContainer));
