import moment from 'moment';

export const convertFiltersToSearchParameters = (filters, searchParameters) => {
  const newSearchParameters = {};

  filters?.forEach((filter) => {
    if (filter?.type === 'sorting') {
      newSearchParameters[filter.name] = [filter];
    } else if (filter?.type === 'component') {
      newSearchParameters[filter.name] =
        filter.value instanceof Array
          ? filter.value.map((v) => (typeof v === 'string' || v instanceof String ? v : v.id))
          : filter.value?.id || filter.value;
    } else if (filter?.type === 'date' || filter?.type === 'date-time') {
      if (filter.value) {
        newSearchParameters[filter.name] = filter.value ? moment(filter.value) : null;
      } else {
        delete newSearchParameters[filter.name];
      }
    } else if (filter?.type === 'date-range') {
      const [start, end] = filter.value || [];
      if (start || end) {
        newSearchParameters[filter.name] = {};
        if (start) newSearchParameters[filter.name]['start'] = start ? moment(start) : null;
        if (end) newSearchParameters[filter.name]['end'] = end ? moment(end) : null;
      }
    } else if (filter.value) {
      newSearchParameters[filter.name] = filter.value;
    }
  });

  return newSearchParameters;
};

export const activeFilterCount = (filters) => {
  return filters?.filter((f) => f.value != null && f.value?.length > 0)?.length || 0;
};
