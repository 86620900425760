import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import * as actionActions from '@actions/actionActions';
import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';

import { swapArrayLocs } from '@utils/arrayUtils';

import ActionCard from './ActionCard';

const SortableContainer = sortableContainer(({ children, className }) => (
  <div className={className}>{children}</div>
));

const SortableItem = sortableElement(({ key, children }) => {
  return (
    <div key={key} style={{ zIndex: 1001, width: '100%' }}>
      {children}
    </div>
  );
});

class ActionCards extends Component {
  static defaultProps = {
    active: false,
    depth: 0,
    showConsignment: false,
    tripSequence: false,
  };
  constructor(props) {
    super(props);

    const sequenceSelector = props.tripSequence ? 'tripSequenceNr' : 'sequenceNr';

    this.state = {
      actions: this.props.actions?.filter((a) => a),
      sortedActions: [...(this.props.actions || [])]
        ?.filter((a) => a)
        .sort((a, b) => a.entity?.[sequenceSelector] - b.entity?.[sequenceSelector]),
    };
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const { actions, parentAction, updateAction, tripSequence } = this.props;
    const sequenceSelector = tripSequence ? 'tripSequenceNr' : 'sequenceNr';

    let newActions = [...actions];

    newActions = [...swapArrayLocs(newActions, oldIndex, newIndex)]
      .map((action, index) => {
        let newAction = { ...action };
        let newEntity = { ...newAction.entity };
        newEntity[sequenceSelector] = index;
        newAction.entity = newEntity;
        return newAction;
      })
      .sort((a, b) => a?.[sequenceSelector] - b?.[sequenceSelector]);

    this.setState({
      actions: newActions,
      sortedActions: [...newActions].sort(
        (a, b) => a.entity?.[sequenceSelector] - b.entity?.[sequenceSelector]
      ),
    });

    const newParentAction = { ...parentAction };
    newParentAction.actions = newActions;
    updateAction(newParentAction);
  };

  render() {
    const { moveActions, depth, showConsignment, onParentActionSortEnd, tripSequence } = this.props;
    const { sortedActions } = this.state;
    let prev;
    let next;
    let isNextGrouped;

    return (
      <div className="actions-cards">
        {depth === 0 ? (
          <>
            {onParentActionSortEnd ? (
              <SortableContainer
                onSortEnd={({ oldIndex, newIndex }) => onParentActionSortEnd(oldIndex, newIndex)}
                useDragHandle
                hideSortableGhost={false}
              >
                <div>
                  {sortedActions.map((association, index) => {
                    if (index > 0) {
                      prev = sortedActions[index - 1];
                    }
                    if (index + 1 <= sortedActions.length - 1) {
                      next = sortedActions[index + 1];
                    } else {
                      next = null;
                    }
                    isNextGrouped = next
                      ? association?.entity?.location?.entity?.id ===
                        next?.entity?.location?.entity?.id
                      : false;
                    return (
                      <SortableItem
                        index={index}
                        key={`action-${association.entity.id}-${association.entity.lifeCycle}`}
                      >
                        <ActionCard
                          tripSequence={tripSequence}
                          depth={depth}
                          index={index}
                          isGrouped={
                            prev
                              ? association?.entity?.location?.entity?.id ===
                                prev?.entity?.location?.entity?.id
                              : index === 0
                              ? isNextGrouped
                              : false
                          }
                          isDraggable={true}
                          isNextGrouped={isNextGrouped}
                          key={`action-${association.entity.id}-${association.entity.lifeCycle}`}
                          action={association.entity}
                          actions={[...sortedActions]}
                          moveActions={moveActions}
                          showConsignment={showConsignment}
                          defaultDate={this.props.defaultDate}
                          {...this.props}
                        />
                      </SortableItem>
                    );
                  })}
                </div>
              </SortableContainer>
            ) : (
              <>
                {sortedActions.map((association, index) => {
                  if (index > 0) {
                    prev = sortedActions[index - 1];
                  }
                  if (index + 1 <= sortedActions.length - 1) {
                    next = sortedActions[index + 1];
                  } else {
                    next = null;
                  }
                  isNextGrouped = next
                    ? association?.entity?.location?.entity?.id ===
                      next?.entity?.location?.entity?.id
                    : false;
                  return (
                    <ActionCard
                      depth={depth}
                      index={index}
                      isGrouped={
                        prev
                          ? association?.entity?.location?.entity?.id ===
                            prev?.entity?.location?.entity?.id
                          : index === 0
                          ? isNextGrouped
                          : false
                      }
                      isNextGrouped={isNextGrouped}
                      key={`action-${association.entity.id}-${association.entity.lifeCycle}`}
                      action={association.entity}
                      actions={[...sortedActions]}
                      moveActions={moveActions}
                      showConsignment={showConsignment}
                      defaultDate={this.props.defaultDate}
                      {...this.props}
                    />
                  );
                })}
              </>
            )}
          </>
        ) : (
          <SortableContainer onSortEnd={this.onSortEnd} useDragHandle hideSortableGhost={false}>
            <div>
              {sortedActions.length > 1
                ? sortedActions.map((association, index) => (
                    <SortableItem
                      index={index}
                      key={`action-${association.entity.id}-${association.entity.lifeCycle}`}
                    >
                      <ActionCard
                        tripSequence={tripSequence}
                        depth={depth}
                        index={index}
                        isDraggable={true}
                        action={association.entity}
                        actions={[...sortedActions]}
                        moveActions={moveActions}
                        showConsignment={showConsignment}
                        {...this.props}
                      />
                    </SortableItem>
                  ))
                : sortedActions.map((association, index) => (
                    <ActionCard
                      tripSequence={tripSequence}
                      depth={depth}
                      index={index}
                      isDraggable={false}
                      key={`action-${association.entity.id}-${association.entity.lifeCycle}`}
                      action={association.entity}
                      actions={[...sortedActions]}
                      moveActions={moveActions}
                      showConsignment={showConsignment}
                      {...this.props}
                    />
                  ))}
            </div>
          </SortableContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTripAction: (trip, action) => dispatch(tripActions.updateTripAction(trip, action)),
    updateTripActionLifeCycle: (trip, action, lifeCycle) =>
      dispatch(tripActions.updateTripActionLifeCycle(trip, action, lifeCycle)),
    updateTripActionTimes: (trip, action, startTime, endTime) =>
      dispatch(tripActions.updateTripActionTimes(trip, action, startTime, endTime)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
    updateAction: (action) => dispatch(actionActions.updateAction(action)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ActionCards));
