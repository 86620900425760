import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import TripForm from './TripForm';

export default class UpdateTripPage extends Component {
  componentDidMount() {
    const { onChange, ownProps } = this.props;
    const newTrip = { ...ownProps.trip };

    if (newTrip.type === 'forwarded') {
      newTrip.spotQuote = true;
    }

    onChange('formTrip', newTrip);
  }

  render() {
    const { t, formTrip, isFetchingTrip, updateTrip } = this.props;

    if (!formTrip || isFetchingTrip) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1 className="no-margin-top">{t('trip.update')}</h1>
        <TripForm
          {...this.props}
          key={`${formTrip.nonce}-${formTrip.id}`}
          updating={true}
          onSubmit={(trip) => updateTrip(trip)}
        />
      </AsideView>
    );
  }
}
