export const tripConstants = {
  TRIP_CHANGE_VALUE: 'TRIP_CHANGE_VALUE',

  GET_TRIP_STARTED: 'GET_TRIP_STARTED',
  GET_TRIP_SUCCESS: 'GET_TRIP_SUCCESS',
  GET_TRIP_FAILURE: 'GET_TRIP_FAILURE',

  GET_TRIPS_STARTED: 'GET_TRIPS_STARTED',
  GET_TRIPS_SUCCESS: 'GET_TRIPS_SUCCESS',
  GET_TRIPS_FAILURE: 'GET_TRIPS_FAILURE',

  SEARCH_TRIPS_STARTED: 'SEARCH_TRIPS_STARTED',
  SEARCH_TRIPS_SUCCESS: 'SEARCH_TRIPS_SUCCESS',
  SEARCH_TRIPS_FAILURE: 'SEARCH_TRIPS_FAILURE',

  CREATING_TRIP_STARTED: 'CREATING_TRIP_STARTED',
  CREATING_TRIP_SUCCESS: 'CREATING_TRIP_SUCCESS',
  CREATING_TRIP_FAILURE: 'CREATING_TRIP_FAILURE',

  CREATING_TRIPS_STARTED: 'CREATING_TRIPS_STARTED',
  CREATING_TRIPS_SUCCESS: 'CREATING_TRIPS_SUCCESS',
  CREATING_TRIPS_FAILURE: 'CREATING_TRIPS_FAILURE',

  UPDATING_TRIP_STARTED: 'UPDATING_TRIP_STARTED',
  UPDATING_TRIP_SUCCESS: 'UPDATING_TRIP_SUCCESS',
  UPDATING_TRIP_FAILURE: 'UPDATING_TRIP_FAILURE',

  UPDATING_TRIPS_STARTED: 'UPDATING_TRIPS_STARTED',
  UPDATING_TRIPS_SUCCESS: 'UPDATING_TRIPS_SUCCESS',
  UPDATING_TRIPS_FAILURE: 'UPDATING_TRIPS_FAILURE',

  DELETE_TRIP_STARTED: 'DELETE_TRIP_STARTED',
  DELETE_TRIP_SUCCESS: 'DELETE_TRIP_SUCCESS',
  DELETE_TRIP_FAILURE: 'DELETE_TRIP_FAILURE',

  CONFIRM_TRIP_STARTED: 'CONFIRM_TRIP_STARTED',
  CONFIRM_TRIP_SUCCESS: 'CONFIRM_TRIP_SUCCESS',
  CONFIRM_TRIP_FAILURE: 'CONFIRM_TRIP_FAILURE',

  CANCEL_TRIPS_STARTED: 'CANCEL_TRIPS_STARTED',
  CANCEL_TRIPS_SUCCESS: 'CANCEL_TRIPS_SUCCESS',
  CANCEL_TRIPS_FAILURE: 'CANCEL_TRIPS_FAILURE',

  CONFIRM_TRIPS_STARTED: 'CONFIRM_TRIPS_STARTED',
  CONFIRM_TRIPS_SUCCESS: 'CONFIRM_TRIPS_SUCCESS',
  CONFIRM_TRIPS_FAILURE: 'CONFIRM_TRIPS_FAILURE',

  START_TRIP_STARTED: 'START_TRIP_STARTED',
  START_TRIP_SUCCESS: 'START_TRIP_SUCCESS',
  START_TRIP_FAILURE: 'START_TRIP_FAILURE',

  FINISH_TRIP_STARTED: 'FINISH_TRIP_STARTED',
  FINISH_TRIP_SUCCESS: 'FINISH_TRIP_SUCCESS',
  FINISH_TRIP_FAILURE: 'FINISH_TRIP_FAILURE',

  FINISH_TRIP_AND_START_NEW_STARTED: 'FINISH_TRIP_AND_START_NEW_STARTED',
  FINISH_TRIP_AND_START_NEW_SUCCESS: 'FINISH_TRIP_AND_START_NEW_SUCCESS',
  FINISH_TRIP_AND_START_NEW_FAILURE: 'FINISH_TRIP_AND_START_NEW_FAILURE',

  CANCEL_TRIP_STARTED: 'CANCEL_TRIP_STARTED',
  CANCEL_TRIP_SUCCESS: 'CANCEL_TRIP_SUCCESS',
  CANCEL_TRIP_FAILURE: 'CANCEL_TRIP_FAILURE',

  UPDATE_TRIP_ACTION_STARTED: 'UPDATE_TRIP_ACTION_STARTED',
  UPDATE_TRIP_ACTION_SUCCESS: 'UPDATE_TRIP_ACTION_SUCCESS',
  UPDATE_TRIP_ACTION_FAILURE: 'UPDATE_TRIP_ACTION_FAILURE',
};
