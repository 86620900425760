import React, { PureComponent, Suspense } from 'react';

import i18next from 'i18next';

import CreateMessageAutomationContainer from '@containers/messageAutomation/crud/CreateMessageAutomationContainer';

import BreadCrumbs from './BreadCrumbs';
import CloseButton from './CloseButton';
import PopOver from './PopOver';

export default class ComponentStack extends PureComponent {
  state = {
    animationHasEnded: false,
    hasChanged: false,
    showPopOver: false,
  };

  addExtraProps(Component, componentName, extraProps) {
    const index = this.props.components.findIndex((comp) => comp.name === componentName);

    const handleHasChanged = (changed) => {
      if (typeof index === 'undefined' || changed === undefined) {
        console.error('Index and changed must be defined');
        return;
      }

      this.setState((prevState) => ({
        hasChanged: {
          ...prevState.hasChanged,
          [index]: changed,
        },
      }));
    };

    return Component ? (
      <Component.type {...Component.props} {...extraProps} setHasChanged={handleHasChanged} />
    ) : null;
  }

  renderComponent(component) {
    if (component.path) {
      const LoadedComponent = React.lazy(() =>
        import(
          `@/containers/${component.path.replace('components/', '').replace('containers/', '')}`
        )
      );

      return (
        <Suspense fallback={<></>}>
          <LoadedComponent {...component.props} />
        </Suspense>
      );
    }

    return component
      ? this.addExtraProps(component.component, component.name, { ...this.state })
      : null;
  }

  onClose = () => {
    const { hasChanged } = this.state;

    const unsavedChanges = Object.values(hasChanged).some((changed) => changed);
    if (unsavedChanges) {
      this.setState({ showPopOver: true });
    } else {
      this.closeComponentStack();
    }
  };

  closeComponentStack = () => {
    const { popStack } = this.props;

    this.setState({ hasChanged: false, showPopOver: false }, () => popStack?.());
  };

  handlePopOverClose = (confirm) => {
    if (confirm) {
      this.closeComponentStack();
    }
    this.setState({ showPopOver: false });
  };

  render() {
    const { t, components } = this.props;
    const { showPopOver } = this.state;

    const componentsLength = components.length;
    return (
      <>
        {showPopOver && (
          <PopOver
            className="select-pop-over"
            isActive={showPopOver}
            onClose={() => this.handlePopOverClose(false)}
          >
            <div className="box">
              <div className="title font-x1">{i18next.t('form.changes.header')}</div>
              <div className="text">{i18next.t('form.changes.body')}</div>

              <div className="choice">
                <button
                  href=""
                  onClick={(e) => {
                    this.closeComponentStack();

                    this.setState({
                      showPopOver: false,
                    });
                  }}
                >
                  {i18next.t('form.changes.close')}
                </button>
                <div
                  className="as-link"
                  onClick={() => {
                    this.handlePopOverClose();
                  }}
                >
                  {i18next.t('form.changes.goBack')}
                </div>
              </div>
            </div>
          </PopOver>
        )}
        <PopOver isActive={components && componentsLength > 0} onClose={() => this.onClose()}>
          <div className="component-stack">
            {components.map((component, index) => {
              return (
                <div
                  className={`rightview scrollable thick ${
                    component.animateOut ? 'animateOut' : 'animateIn'
                  } ${componentsLength - 1 === index ? '' : ' below'} ${
                    component.fullWidth ? ' full-width' : ''
                  }`}
                  key={`component-stack-${index}`}
                  style={{
                    zIndex: componentsLength - 1 === index ? 10 + componentsLength : -1,
                  }}
                  onClick={(e) => {
                    if (componentsLength - 1 !== index) {
                      this.onClose();
                    }
                  }}
                  onAnimationEnd={() => {
                    this.setState({ animationHasEnded: true });
                  }}
                >
                  <div
                    className={`pop-up-container ${component.className ? component.className : ''}`}
                  >
                    <div className="header-actions">
                      <BreadCrumbs crumbs={[]} />
                      <CloseButton onClick={() => this.onClose()} />
                    </div>
                    {this.renderComponent(component)}
                  </div>
                </div>
              );
            })}
          </div>
        </PopOver>
      </>
    );
  }
}
