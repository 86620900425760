import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import NoData from '@uicomponents/NoData';

import ActionCards from '../action/ActionCards';
import ADRView from '../general/ADRView';

class ItemView extends Component {
  render() {
    const { t, goods } = this.props;
    return (
      <div className="goods-view">
        <h2 className="no-margin-bottom">{goods.name}</h2>
        <p className="no-margin-top">#{goods.id}</p>
        {goods.quantity ? (
          <div className="quantity">
            <b>{t('goods.quantity')}</b>: {goods.quantity}
          </div>
        ) : null}
        {goods.weight && (
          <div className="weight">
            <b>{t('goods.weight')}</b>: {`${goods.weight?.value || ''} ${goods.weight?.unit || ''}`}
          </div>
        )}
        {goods.grossWeight && (
          <div className="grossWeight">
            <b>{t('goods.grossWeight')}</b>:{' '}
            {`${goods.grossWeight.value} ${goods.grossWeight?.unit || ''}`}
          </div>
        )}
        {goods.width && goods.length && goods.height && (
          <div>
            <b>{t('goods.dimensions')}</b>:{' '}
            {`${goods.length.value} x ${goods.width.value} x ${goods.height.value} ${goods.width.unit}`}
          </div>
        )}
        <div className="description">
          <b>{t('consignment.description')}</b>:{' '}
          {goods.description ? (
            <div>{goods.description}</div>
          ) : (
            <NoData className="left">{t('consignment.description.notFound')}</NoData>
          )}
        </div>
        {goods.barCode && (
          <div className="barCode">
            <b>{t('goods.barCode')}</b>: {goods.barCode}
          </div>
        )}
        {goods.productsType && (
          <div className="productsType">
            <b>{t('goods.productsType')}</b>: {goods.productsType}
          </div>
        )}
        {goods.packagingMaterial && (
          <div className="packagingMaterial">
            <b>{t('goods.packagingMaterial')}</b>: {goods.packagingMaterial}
          </div>
        )}
        {goods.adr ? (
          <>
            <div>
              <b>{t('adr.header')}</b>
            </div>
            <ADRView adr={goods.adr} />
          </>
        ) : null}

        <ActionCards active={false} actions={goods.actions} />
      </div>
    );
  }
}
export default withTranslation('translation')(ItemView);
