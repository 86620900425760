import React, { Component } from 'react';

class LedgerTable extends Component {
  groupByCategory(data) {
    return data.reduce((acc, entry) => {
      if (!acc[entry.category]) {
        acc[entry.category] = [];
      }
      acc[entry.category].push(entry);
      return acc;
    }, {});
  }

  render() {
    const { t, data } = this.props;

    const groupedData = this.groupByCategory(data);

    let totalActualDebit = 0;
    let totalActualCredit = 0;
    let totalExpectedDebit = 0;
    let totalExpectedCredit = 0;

    Object.values(groupedData).forEach((entries) => {
      entries.forEach((entry) => {
        totalActualDebit += entry.actualDebit;
        totalActualCredit += entry.actualCredit;
        totalExpectedDebit += entry.expectedDebit;
        totalExpectedCredit += entry.expectedCredit;
      });
    });

    const totalActualResult = totalActualDebit - totalActualCredit;
    const totalExpectedResult = totalExpectedDebit - totalExpectedCredit;

    return (
      <div>
        <div className="ledger-table">
          <table
            style={{
              width: '100%',
              textAlign: 'center',
              borderCollapse: 'collapse',
            }}
          >
            <thead>
              <tr>
                <th>{t('form.label.category')}</th>
                <th>{t('ledger.actualDebit')}</th>
                <th>{t('ledger.actualCredit')}</th>
                <th>{t('ledger.expectedDebit')}</th>
                <th>{t('ledger.expectedCredit')}</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(groupedData).map(([category, entries]) => {
                let categoryActualDebit = 0;
                let categoryActualCredit = 0;
                let categoryExpectedDebit = 0;
                let categoryExpectedCredit = 0;

                entries.forEach((entry) => {
                  categoryActualDebit += entry.actualDebit;
                  categoryActualCredit += entry.actualCredit;
                  categoryExpectedDebit += entry.expectedDebit;
                  categoryExpectedCredit += entry.expectedCredit;
                });

                return (
                  <tr key={category}>
                    <td>{category}</td>
                    <td>{categoryActualDebit}</td>
                    <td>{categoryActualCredit}</td>
                    <td>{categoryExpectedDebit}</td>
                    <td>{categoryExpectedCredit}</td>
                  </tr>
                );
              })}
              <tr>
                <td>
                  <strong>{t('form.label.total')}</strong>
                </td>
                <td>
                  <strong>{totalActualDebit}</strong>
                </td>
                <td>
                  <strong>{totalActualCredit}</strong>
                </td>
                <td>
                  <strong>{totalExpectedDebit}</strong>
                </td>
                <td>
                  <strong>{totalExpectedCredit}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>{t('form.label.result')}</strong>
                </td>
                <td colSpan="2">
                  <strong>{totalActualResult}</strong>
                </td>
                <td colSpan="2">
                  <strong>{totalExpectedResult}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default LedgerTable;
