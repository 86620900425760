import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import ReactSVG from 'react-svg';

import MessagesContainer from '@containers/chat/MessagesContainer';
import CreateMessageContainer from '@containers/chat/crud/CreateMessageContainer';
import ConsignmentTripsContainer from '@containers/consignment/ConsignmentTripsContainer';
import UpdateConsignmentContainer from '@containers/consignment/crud/UpdateConsignmentContainer';
import CreateTripContainer from '@containers/trip/crud/CreateTripContainer';

import LedgerTable from '@uicomponents/Ledger';
import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';

import { getToBePlannedActions } from '@utils/actionUtils';

import ConsignmentView from './ConsignmentView';

const DocumentsContainer = lazy(() => import('@containers/document/DocumentsContainer'));

class ConsignmentDetailView extends Component {
  constructor(props) {
    super(props);

    this.messagesRef = React.createRef();
    this.state = {
      tab: 'information',
    };
  }

  componentDidMount() {
    const { consignment, getLedgerEntriesByEntity } = this.props;

    getLedgerEntriesByEntity?.({ entityType: 'consignment', entityId: consignment?.id });
  }

  componentWillUnmount() {
    this.messagesRef = null;
  }

  render() {
    const {
      t,
      platform,
      consignment,
      isFetchingConsignment,
      chat,
      chatIsFetching,
      noHeader,
      standalone,
      addToStack,
      changeValue,
      ledgerEntries,
    } = this.props;

    const { tab } = this.state;

    if (!consignment || isFetchingConsignment) return <Loader />;

    return (
      <div className={`consignment-detail${standalone ? ' consignment-detail--standalone' : ''}`}>
        {!noHeader && (
          <div className="flex-container justify-between no-wrap">
            <div>
              <div className="">
                <h1 className="no-margin with-flair">
                  {consignment.name} {consignment.type && <span>{consignment.status}</span>}
                </h1>
                <h3 className="no-margin-top">#{consignment.consignmentNr}</h3>
              </div>
            </div>
            <div className="self-center flex-container action-icons">
              <div
                className="action-icon action"
                onClick={(e) => {
                  e.preventDefault();
                  addToStack({
                    name: t('trip.new'),
                    component: (
                      <CreateTripContainer
                        targetActions={getToBePlannedActions([consignment])}
                        targetConsignments={[consignment]}
                      />
                    ),
                  });
                }}
              >
                <ReactSVG src="/icons/waypoint.svg" />
              </div>
              <div
                className="action-icon action"
                onClick={(e) => {
                  e.preventDefault();
                  addToStack({
                    name: t('location.update.header'),
                    component: <UpdateConsignmentContainer consignment={{ ...consignment }} />,
                  });
                }}
              >
                <ReactSVG src="/icons/pencil.svg" />
              </div>
            </div>
          </div>
        )}
        <ScrollContainer
          verticle={false}
          hideScrollbars={false}
          className="align-items-center d-flex gap-10 tab-links-container"
        >
          <div
            className={`tab-links${tab === 'information' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'information' })}
          >
            {t('consignment.tabs.information')}
          </div>
          <div
            className={`tab-links${tab === 'messages' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'messages' })}
          >
            {t('consignment.tabs.messages')}
          </div>
          <div
            className={`tab-links${tab === 'trips' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'trips' })}
          >
            {t('consignment.tabs.trips')}
          </div>
          <div
            className={`tab-links${tab === 'documents' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'documents' })}
          >
            {t('documents.header')}
          </div>
          <div
            className={`tab-links${tab === 'ledger' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'ledger' })}
          >
            {t('ledger')}
          </div>
        </ScrollContainer>
        <section
          className={`consignment-detail__box scrollable scrollable__y activity${
            tab === 'information' ? ' active' : ''
          }${standalone ? ' scrollable' : ''}`}
        >
          {tab === 'information' ? (
            <ConsignmentView
              key={consignment.id}
              platform={platform}
              simplifiedView={true}
              addToStack={addToStack}
              consignment={consignment}
              notStackedFromTransportOrder={true}
              getLedgerEntriesByEntity={this.props.getLedgerEntriesByEntity}
            />
          ) : null}
        </section>
        <section
          className={`consignment-detail__box consignment-detail__box--messages activity${
            tab === 'messages' ? ' active' : ''
          }`}
        >
          {tab === 'messages' &&
          consignment.chat &&
          chat &&
          consignment.chat.id === chat.id &&
          !chatIsFetching ? (
            <div className="chat">
              <div className="main">
                <MessagesContainer consignment={consignment} />
                <CreateMessageContainer
                  onMessageCreate={() =>
                    (this.messagesRef.current.scrollTop = this.messagesRef.current.scrollHeigh)
                  }
                />
              </div>
            </div>
          ) : chatIsFetching ? (
            <Loader />
          ) : (
            <div className="not-found-action-box">
              <div className="icon">
                <ReactSVG src="/icons/space.svg" />
              </div>
              <NoData>{t('chats.notFound')}</NoData>
            </div>
          )}
        </section>
        <section
          className={`consignment-detail__box activity${tab === 'trips' ? ' active' : ''}${
            standalone ? ' scrollable' : ''
          }`}
        >
          {tab === 'trips' ? (
            <ConsignmentTripsContainer key={consignment.id} consignment={consignment} />
          ) : null}
        </section>
        <section
          className={`consignment-detail__box activity${tab === 'ledger' ? ' active' : ''}${
            standalone ? ' scrollable' : ''
          }`}
        >
          {tab === 'ledger' ? (
            <div className="ledger my-10">
              <LedgerTable t={t} data={ledgerEntries || []} />
            </div>
          ) : null}
        </section>
        <section
          className={`consignment-detail__box activity${tab === 'documents' ? ' active' : ''}${
            standalone ? ' scrollable' : ''
          }`}
        >
          {tab === 'documents' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <DocumentsContainer
                entity={consignment}
                entityType="consignment"
                onChange={(newConsignment) => {
                  const { updateConsignment } = this.props;

                  updateConsignment(newConsignment);
                }}
                callback={(newConsignment) => {
                  changeValue('consignment', newConsignment || {});
                }}
              />
            </Suspense>
          ) : null}
        </section>
      </div>
    );
  }
}
export default withTranslation('translation')(ConsignmentDetailView);
