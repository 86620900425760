import { ledgerConstants } from '../constants/ledger.constants';

const initialState = {
  isFetchingLedgerEntries: false,
  ledgerEntries: [],
};

export default function ledger(state = initialState, action) {
  switch (action.type) {
    case ledgerConstants.GET_LEDGER_ENTRIES_BY_ENTITY_STARTED:
      return {
        ...state,
        isFetchingLedgerEntries: true,
      };
    case ledgerConstants.GET_LEDGER_ENTRIES_BY_ENTITY_FAILURE:
      return {
        ...state,
        ledgerEntries: [],
        error: action.message || '',
        isFetchingLedgerEntries: false,
      };
    case ledgerConstants.GET_LEDGER_ENTRIES_BY_ENTITY_SUCCESS:
      return {
        ...state,
        isFetchingLedgerEntries: false,
        ledgerEntries: [...state.ledgerEntries, ...action.ledgerEntries],
      };
    default:
      return state;
  }
}
